import React from 'react';
import {Avatar} from '../../Avatar/Avatar';
import {IonItem, IonLabel, IonSkeletonText} from '@ionic/react';

import './StoreItem.scss';

interface StoreItemProps {
  img?: string;
  name?: string;
  address?: string;
  isLoader?: boolean;
  routerLink?: string;
  onClick?: Function;
}

export const StoreItem: React.FC<StoreItemProps> = ({img, routerLink, name, address, isLoader, onClick}) => {

  const onClickHandler = (e: any) => {
    if (onClick)
      onClick(e);
  }

  if (isLoader) {
    return (
      <IonItem className="order-item">
        <IonSkeletonText animated style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
        <IonLabel className="order-item-info">
          <IonSkeletonText animated style={{width: '40%', height: '16px', marginBottom: '5px'}}/>
          <IonSkeletonText animated style={{width: '100%', height: '12px'}}/>
        </IonLabel>
      </IonItem>
    )
  }

  return (
    <IonItem routerLink={routerLink} className="shop-item" onClick={onClickHandler}>
      <Avatar label="S"/>
      <IonLabel className="shop-item-info">
        <h3>{name}</h3>
        <p>{address}</p>
      </IonLabel>
    </IonItem>
  );
}
