// @ts-nocheck
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IonDatetime, IonLabel } from "@ionic/react";
import Axios from "axios";
import moment from "moment";

import { APIs } from "../../../../services/apiService";
import { dailyProductColumns } from "../../../../constants/dailyProduction";

import "./EditDailyProductModal.scss";

export const EditDailyProductModal = (props: any) => {
  const [isOpen, setIsOpen] = useState(props.displayModal);
  const [data, setData] = useState(props.selectedRow);

  const toggleModal = () => {
    props.toggleModal();
  };

  const updateData = (e: any, column: any) => {
    if (column.type === "date")
      setData((data) => ({
        ...data,
        [column.columnName]: moment(e.detail.value).format("YYYY-MM-DD"),
      }));
    else setData((data) => ({ ...data, [column.columnName]: e.target.value }));
  };

  const updateRow = () => {
    let dataToSend = {};
    dailyProductColumns.forEach((col) => {
      if (col.columnName === "product_size_id") {
        dataToSend[col.columnName] = props.selectedRow["product_size"].id;
      } else dataToSend[col.columnName] = data[col.columnName];
    });

    Axios.put(
      APIs["daily-productions"].update(props.selectedRow.id),
      dataToSend
    )
      .then((res) => {
        props.getDailyList();
        toast.success("Daily product has been saved");
        toggleModal();
      })
      .catch((e) => {
        toast.error(e?.customErrorMessage ?? "ERROR Updating Product");
      });
  };

  useEffect(() => {
    let selectedData = {};
    if (props.selectedRow) {
      dailyProductColumns.forEach((col) => {
        if (col.columnName === "product_size_id") {
          selectedData[col.columnName] = props.selectedRow["product_size"].code;
        } else selectedData[col.columnName] = props.selectedRow[col.columnName];
      });
      setData(selectedData);
    }
  }, [props.selectedRow]);

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Edit Daily Production Item:</IonTitle>
          <IonButtons slot="end">
            <IonButton
              className="text-success font-weight-bold"
              onClick={updateRow}
            >
              Save
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={toggleModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {data &&
          dailyProductColumns.map((column) => (
            <IonItem className="product-form-input">
              {column.component === "input" ? (
                <>
                  <IonLabel position="floating">{column.label}</IonLabel>
                  <IonInput
                    type={column.type}
                    value={data[column.columnName]}
                    onIonChange={(e) => updateData(e, column)}
                  />
                </>
              ) : (
                <>
                  <IonLabel position="floating">{column.label}</IonLabel>
                  <IonDatetime
                    displayFormat="DD-MM-YYYY"
                    value={data[column.columnName]}
                    onIonChange={(e) => updateData(e, column)}
                  />
                </>
              )}
            </IonItem>
          ))}
      </IonContent>
    </IonModal>
  );
};
