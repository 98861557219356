import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonModal,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonAvatar,
  IonListHeader,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
} from "@ionic/react";
import { IStore } from "../../../models/Store";

import "./ProductModal.scss";

import axios from "axios";
import _ from "lodash";
import { APIs } from "../../../services/apiService";
import { IProduct } from "../../../models/Product";
import { IProductSize } from "../../../models/ProductSize";
import { OrderItem } from "../OrderItem/OrderItem";
import { toast } from "react-toastify";
import { LastOrderItemFull } from "../LastOrderItemFull/LastOrderItemFull";
import { IOrder } from "../../../models/Order";

interface OrderProductModalProps {
  open: boolean;
  productId: any;
  orderSizes: Array<any>;
  store: IStore;
  onDidDismiss: Function;
  changeOrderSize: Function;
  setOrderSize: Function;
}

export const OrderProductModal: React.FC<OrderProductModalProps> = ({
  open,
  productId,
  onDidDismiss,
  orderSizes,
  store,
  changeOrderSize,
  setOrderSize,
}) => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<IProduct | null>();
  const [loadingLastOrders, setLoadingLastOrders] = useState(false);
  const [lastOrders, setLastOrders] = useState<any>([]);

  const onShowModal = () => {
    setLoading(true);
    setLoadingLastOrders(true);

    axios
      .get(APIs.products.show(productId))
      .then((res) => {
        setProduct(res.data.data);
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error getting product info");
        onHideModal();
      })
      .finally(() => {
        setLoading(false);
      });

    axios
      .get(APIs.products.lastOrdersOfStore(productId, store.id))
      .then((res) => {
        setLastOrders(
          (res.data.data = res.data.data.filter(
            (order: IOrder, index: number, self: IOrder[]) =>
              index === self.findIndex((t) => t.id === order.id)
          ))
        );
        setLoadingLastOrders(false);
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error getting last orders");
      });
  };

  const onHideModal = () => {
    setProduct(null);
    onDidDismiss();
  };

  return (
    <IonModal
      cssClass="order-product-modal"
      isOpen={open}
      onWillPresent={onShowModal}
      onDidDismiss={onHideModal}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{product?.title}:</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onHideModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol sizeSm="5" sizeXs="12">
              <IonAvatar className="product-img">
                {product?.image ? <img src={product?.image} alt="" /> : ""}
              </IonAvatar>
            </IonCol>
            <IonCol sizeSm="7" sizeXs="12">
              <IonRow>
                <IonCol size="12">
                  <h1 className="product-name">{product?.title}:</h1>
                  <IonRow>
                    <IonCol size="3">
                      <IonLabel>VAT: </IonLabel>
                    </IonCol>
                    <IonCol size="9" className="product-vat">
                      <IonCheckbox
                        title="vat"
                        checked={product?.vat == 1 ? true : false}
                        disabled
                      ></IonCheckbox>
                    </IonCol>
                  </IonRow>
                </IonCol>
                {_.map(product?.sizes, (size) => (
                  <OrderProductSize
                    key={size.id}
                    size={size}
                    orderSizes={orderSizes}
                    setOrderSize={setOrderSize}
                    changeOrderSize={changeOrderSize}
                    vat={product?.vat}
                  />
                ))}
              </IonRow>
            </IonCol>
            <IonCol size="12">
              <IonList>
                <IonListHeader>
                  <IonLabel className="last-orders__title">
                    Last orders:
                  </IonLabel>
                </IonListHeader>
                {loadingLastOrders
                  ? _.times(5, (i) => <OrderItem key={i} isLoader={true} />)
                  : _.map(lastOrders, (lastOrder, index) => (
                      <LastOrderItemFull
                        order={lastOrder}
                        productId={product?.id}
                      />
                    ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* Loading */}
        <IonLoading isOpen={loading} message={"Please wait..."} />
      </IonContent>
    </IonModal>
  );
};

interface OrderProductSizeProps {
  size: IProductSize;
  orderSizes: Array<any>;
  changeOrderSize: Function;
  setOrderSize: Function;
  vat?: boolean | number;
}

/**
 * Order product size component that will render count of sizes in order
 *
 * @param product
 * @param size
 * @param orderSizes
 * @param changeOrderSize
 * @constructor
 */
const OrderProductSize: React.FC<OrderProductSizeProps> = (props) => {
  let { size, orderSizes, changeOrderSize, setOrderSize, vat } = props;
  if (!size.code) {
    return <></>;
  }

  const productSizeInOrder = _.chain(orderSizes)
    .find((oSize) => oSize.id === size.id)
    .value();

  return (
    <IonCol size="12">
      <IonRow>
        <IonCol size="3">
          <div className="product-size-label">{size.type}:</div>
        </IonCol>
        <IonCol size="9" className="change-product-size-count-section">
          <IonButton
            size="small"
            color="danger"
            onClick={() => changeOrderSize(size, "remove")}
          >
            -
          </IonButton>
          <IonSelect
            value={productSizeInOrder?.count ?? 0}
            placeholder=""
            interface="popover"
            onIonChange={(e) => setOrderSize(size, e.detail.value)}
          >
            {_.times(100, (i) => (
              <IonSelectOption key={i} value={i}>
                {i}
              </IonSelectOption>
            ))}
          </IonSelect>
          <IonButton
            size="small"
            color="success"
            onClick={() => changeOrderSize(size, "add")}
          >
            +
          </IonButton>
          <div className="product-price">£{size?.price_with_vat}</div>
        </IonCol>
      </IonRow>
    </IonCol>
  );
};
