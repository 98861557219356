import { IAction, Actions } from "../actionTypes";

const initialState = {
  isAuthenticated: false,
  user: {},
};

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case Actions.AUTH.USER_LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case Actions.AUTH.USER_LOGOUT:
      return {
        isAuthenticated: false,
        user: {},
      };
    case Actions.AUTH.SAGE_SIGN_IN:
      return {};
    default:
      return state;
  }
};
