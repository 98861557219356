import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonLoading,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { APIs } from "../../../services/apiService";
import "./ProductsUpdateModal.scss";

export const ProductsUpdateModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(props.displayModal);
  const [productList, setProductList] = useState<any[]>([]);
  const [filtered, setFiltered] = useState<any[]>([]);
  const [sizesPrices, setSizesPrices] = useState<any>({});
  const [searchProduct, setSearchProduct] = useState("");
  const [changedData, setChangedData] = useState<Set<Number>>(new Set());

  const toggleModal = () => {
    props.toggleModal();
  };

  const getProductList = async () => {
    try {
      setLoading(true);
      let data = await axios.get(APIs.order.productList).then((res) => {
        setLoading(false);
        if (res.data.data?.length > 0)
          return res.data.data
            ?.map((category: any) => category.products)
            .flatMap((x: any[]) => x);
      });
      let tempData: any = {};
      data.forEach((d: any) => {
        d.sizes.forEach((size: any) => {
          tempData[size.id] = size.price;
        });
      });
      setSizesPrices(tempData);
      setProductList(data ?? []);
    } catch (e) {
      setLoading(false);
      // @ts-ignore
      toast.error(e?.customErrorMessage ?? "ERROR getting products");
    }
  };

  useEffect(() => {
    if (productList.length === 0 && isOpen) getProductList();
    if (searchProduct?.length)
      setFiltered(
        productList.filter((p: any) =>
          p.title?.toLowerCase()?.includes(searchProduct.toLowerCase())
        )
      );
  }, [isOpen, searchProduct]);

  const updateData = (e: any, id: number) => {
    setChangedData((preData) => preData.add(+id));
    setSizesPrices((prePrices: any) => {
      return {
        ...prePrices,
        [id]: e.target.value,
      };
    });
  };

  const updatePrices = async () => {
    const products = {
      sizes: [],
    };

    changedData.forEach((key) => {
      if (key)
        products["sizes"][products["sizes"].length] = {
          // @ts-ignore
          id: key,
          // @ts-ignore
          price: sizesPrices[key],
        };
    });

    try {
      let data = await axios
        .patch(APIs.products.sizes.prices, products)
        .then((res) => res.data);
      toast.success(data);
    } catch (e) {
      setLoading(false);
      // @ts-ignore
      toast.error(e?.customErrorMessage ?? "ERROR getting products");
    }
  };

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Update Products Prices:</IonTitle>
          <IonButtons slot="end">
            <IonButton
              className="text-success font-weight-bold"
              onClick={updatePrices}
            >
              Save
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={toggleModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonRefresher slot="fixed" onIonRefresh={getProductList}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonSearchbar
          className="px-0 mb-2"
          value={searchProduct}
          onIonChange={(e) => setSearchProduct(e.detail.value!)}
          debounce={2500}
        />

        <div style={{ overflowX: "auto" }}>
          <table className="product-update-table">
            <thead>
              <tr className="product-update-form-size-title">
                <th>
                  <h2>Name</h2>
                </th>
                <th>
                  <h2>S</h2>
                </th>
                <th>
                  <h2>N</h2>
                </th>
                <th>
                  <h2>M</h2>
                </th>
                <th>
                  <h2>L</h2>
                </th>
              </tr>
            </thead>
            <tbody>
              {(searchProduct?.length ? filtered : productList)?.map(
                (p: any) => {
                  return (
                    <tr key={p.title + p.id}>
                      <td className="font-weight-bold">{p.title}</td>
                      {p.sizes?.map((size: any) => (
                        <td key={p.title + p.id + size.type}>
                          {size.code ? (
                            <IonInput
                              value={sizesPrices[size.id]}
                              onIonChange={(e) => updateData(e, size.id)}
                            />
                          ) : (
                            <div style={{ textAlign: "center" }}>{"-"}</div>
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>

        {/* Loading */}
        <IonLoading isOpen={loading} message={"Please wait..."} />
      </IonContent>
    </IonModal>
  );
};
