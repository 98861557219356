import React from 'react';
import {Avatar} from '../../Avatar/Avatar';
import {IonItem, IonLabel, IonSkeletonText} from '@ionic/react';

import './CategoryItem.scss';

interface CategoryItemProps {
  title?: string;
  description?: string;
  isLoader?: boolean;
}


export const CategoryItem: React.FC<CategoryItemProps> = ({title, description, isLoader}) => {

  if(isLoader) {
    return (
      <IonItem className="order-item">
        <IonSkeletonText animated style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
        <IonLabel className="order-item-info">
          <IonSkeletonText animated style={{width: '40%', height: '16px', marginBottom: '5px'}}/>
          <IonSkeletonText animated style={{width: '100%', height: '12px'}}/>
        </IonLabel>
      </IonItem>
    )
  }
  return (
    <IonItem className="category-item">
      <Avatar label="C"/>
      <IonLabel className="category-item-info">
        <h3>{title}</h3>
        <p>{description}</p>
      </IonLabel>
    </IonItem>
  );
}
