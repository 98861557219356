export interface IAction {
  type: string;
  payload?: any;
}

export const Actions = {
  AUTH: {
    USER_LOGIN: "USER_LOGIN",
    USER_LOGOUT: "USER_LOGOUT",
    SAGE_SIGN_IN: "SAGE_SIGN_IN",
  },
  TICKET: {
    SET_NEW_TICKET_COUNT: "SET_NEW_TICKET_COUNT",
  },
  ORDERS: {
    GET_ORDERS: "GET_ORDERS",
  },
};
