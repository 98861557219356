import _ from "lodash";
import { PRODUCT_SIZES } from "../models/ProductSize";
import Resizer from "react-image-file-resizer";

const getDraftSizes = (draft: any) => {
  //@ts-ignore
  const data = draft.data;
  const count = {
    [PRODUCT_SIZES.small]: 0,
    [PRODUCT_SIZES.medium]: 0,
    [PRODUCT_SIZES.normal]: 0,
    [PRODUCT_SIZES.large]: 0,
  };
  data.sizes.forEach((size: any) => {
    count[size.type] += size.count;
  });

  const totalLooses = _.chain(data.looses).map("quantity").sum().value();

  return {
    store_name: data.store.name,
    created_at: data.store.creator.created_at,
    start_at: data.start_at,
    isDraft: true,
    description: data.store.description,
    returns: data.return,
    message: data.message,
    received_payment: data.received_payment,
    created_by: data.store.creator.id,
    name: data.store.creator.name, // creator name
    half_count: data.halves.length,
    loose_count: totalLooses,
    small_count: count[PRODUCT_SIZES.small],
    normal_count: count[PRODUCT_SIZES.normal],
    medium_count: count[PRODUCT_SIZES.medium],
    large_count: count[PRODUCT_SIZES.large],
    returns_count: data.returns.length,
    routerLink: `/new-order/draft-order/${draft.orderId}`,
  };
};

const isObject = (objectToCheck: any) =>
  objectToCheck && objectToCheck.constructor === Object;

const dataUrlToFile = async (
  dataUrl: string,
  fileName: string
): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  // @ts-ignore
  return new File([blob], fileName, { type: "image/png" });
};

const resizeFile = (file: File) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1200,
      900,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export { getDraftSizes, isObject, resizeFile, dataUrlToFile };
