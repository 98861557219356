import { IonDatetime, IonLabel } from "@ionic/react";
import moment from "moment";
import React, { useState } from "react";

export const Date = (props: any) => {
  const { item, updateFormData } = props;
  const [value, setValue] = useState();

  const onChangeHandler = (e: any) => {
    setValue(e.detail.value);
    updateFormData(
      item.columnName,
      moment(e.detail.value).format("YYYY-MM-DD")
    );
  };

  return (
    <div className="w-100 h-100 p-2 overflow-hidden">
      <IonLabel
        className="pl-1 font-weight-bold text-success cell-title"
        position="floating"
      >
        {item.title}
      </IonLabel>
      <IonDatetime
        className="report-date"
        displayFormat="DD/MM//YYYY"
        value={value}
        onIonChange={onChangeHandler}
      />
    </div>
  );
};
