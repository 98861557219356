export const RestockColumns = [
  {
    columnName: "code",
    label: "Product Code",
    type: "number",
    component: "input",
  },
  {
    columnName: "count",
    label: "Count",
    type: "number",
    component: "input",
  },
  {
    columnName: "type",
    label: "Return type",
    type: "select",
    component: "select",
  },
];
