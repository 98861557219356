// @ts-nocheck
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Date } from "../../components/Reports/Date";
import { LinearChart } from "../../components/Reports/LinearChart";
import Picker from "../../components/Reports/Picker";
import { Table } from "../../components/Reports/Table";
import { IReportElement } from "../../models/Report";
import { APIs } from "../../services/apiService";
import { isObject } from "../../utils";
import "./Report.scss";

export const Report: React.FC = () => {
  let { name = "stores" }: { name: string } = useParams();
  const [formData, setFormData] = useState<any>([]);
  const [data, setData] = useState<any>([]);

  let apiUrl = {
    stores: "stores",
    returns: "returns",
    productsPrices: "productsPrices",
  };

  const storesChart = [
    {
      row: 1,
      column: 1,
      width: 4,
      height: 1,
      type: "picker",
      title: "Store Name",
      columnName: "store_id",
    },
    {
      row: 1,
      column: 5,
      width: 4,
      height: 1,
      type: "date",
      title: "Start Date",
      columnName: "start_date",
    },
    {
      row: 1,
      column: 9,
      width: 4,
      height: 1,
      type: "date",
      title: "End Date",
      columnName: "end_date",
    },
    {
      row: 2,
      column: 1,
      width: 12,
      height: 3,
      type: "linear",
      title: "Price/Month",
    },
    { row: 5, column: 1, width: 12, height: 2, type: "table", title: "Top 10" },
  ];

  const returnsChart = [
    {
      row: 1,
      column: 1,
      width: 4,
      height: 1,
      type: "picker",
      title: "Store Name",
      columnName: "store_id",
    },
    {
      row: 1,
      column: 5,
      width: 4,
      height: 1,
      type: "date",
      title: "Start Date*",
      columnName: "start_date",
    },
    {
      row: 1,
      column: 9,
      width: 4,
      height: 1,
      type: "date",
      title: "End Date*",
      columnName: "end_date",
    },
    {
      row: 2,
      column: 1,
      width: 12,
      height: 5,
      type: "table",
      title: "Returns/Store",
    },
  ];

  const productsPricesChart = [
    {
      row: 1,
      column: 1,
      width: 12,
      height: 6,
      type: "table",
      title: "Returns/Store",
    },
  ];

  const renderElement = (elem: IReportElement) => {
    switch (elem.type) {
      case "linear":
        return <LinearChart item={elem} data={data["sale"] ?? []} />;
      case "table":
        return (
          <Table
            item={elem}
            updateFormData={updateFormData}
            data={(name === "stores" ? data["top_products"] : data) ?? []}
          />
        );
      case "date":
        return <Date item={elem} updateFormData={updateFormData} />;
      case "picker":
        return <Picker item={elem} updateFormData={updateFormData} />;
      default:
        return null;
    }
  };

  const renderChart = (elements: IReportElement[]) => {
    return elements.map((elem: IReportElement, index) => {
      if (
        elem.columnName &&
        isObject(formData) &&
        Object.keys(formData).length === 0
      )
        formData[elem.columnName] = null;
      return (
        <div
          style={{
            gridRowStart: elem.row,
            gridColumnStart: elem.column,
            gridRowEnd: elem.row + elem.height,
            gridColumnEnd: elem.column + elem.width,
            overflowY: "auto",
            overflowX: "hidden",
            border: "1px solid #c2c2c2",
          }}
          key={elem.title + index}
        >
          {renderElement(elem)}
        </div>
      );
    });
  };

  const updateFormData = (columnName: string, value: any) => {
    let fd = { ...formData };
    fd[columnName] = value;
    setFormData(fd);

    if (!Object.values(fd).find((val) => val === null))
      axios
        .get(APIs.reports[apiUrl[name]], {
          params: {
            ...fd,
          },
        })
        .then((res) => {
          setData(res.data);
        });
  };

  const getTitle = {
    stores: "Sell Report",
    returns: "Returns Report",
    productsPrices: "Products Prices Report",
  };

  const getData = () => {
    if (name === "stores") return renderChart(storesChart);
    else if (name === "returns") return renderChart(returnsChart);
    else if (name === "productsPrices") return renderChart(productsPricesChart);
  };

  useEffect(() => {
    if (name === "productsPrices")
      axios.get(APIs.reports[apiUrl[name]]).then((res) => {
        if (res?.data?.data?.length > 0) {
          let tableFinalData = res.data.data
            ?.map(({ sizes, title }) => {
              return sizes.map((size) => ({
                ...size,
                title: `${title} (${size.type})`,
              }));
            })
            .flat()
            .sort((a, b) => b.stock - a.stock);

          setData(tableFinalData);
        }
      });
  }, []);

  return (
    <IonPage className="orders-page">
      <IonHeader>
        <IonToolbar color="danger">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dashboard" />
          </IonButtons>
          <IonTitle>{getTitle[name]}</IonTitle>
          {/* {name === "productsPrices" && (
            <div className="header-avatar-container" slot="end">
              <IonButtons slot="end" className="menu-container">
                <IonButton>
                  <a
                    className="menu-btn"
                    type="text/csv"
                    download="products-prices"
                    href={APIs.reports[`${apiUrl[name]}Export`]}
                  >
                    <IonIcon icon={downloadOutline} />
                  </a>
                </IonButton>
              </IonButtons>
            </div>
          )} */}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="dashboard">{getData()}</div>
      </IonContent>
    </IonPage>
  );
};
