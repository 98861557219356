import React from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonItem,
  IonLabel,
  IonInput,
  IonLoading,
  IonToggle,
} from "@ionic/react";

import "./AddLocationModal.scss";

import axios from "axios";
import { APIs } from "../../../services/apiService";
import { toast } from "react-toastify";

export class AddLocationModal extends React.Component<any, any> {
  private locationImageRef: React.RefObject<HTMLImageElement>;

  constructor(props: any) {
    super(props);

    this.state = {
      isModalShowing: false,
      loading: false,
      mode: "add",
      locationData: {
        aisle: "",
        bay: "",
        level: "",
        position: "",
        active: true,
      },
    };

    this.locationImageRef = React.createRef();
  }

  showModal = () => {
    this.setState({
      isModalShowing: true,
      mode: "add",
    });
  };

  showEditModal = (location: any) => {
    this.setState({
      locationData: {
        aisle: location.aisle,
        bay: location.bay,
        level: location.level,
        position: location.position,
        active: location.active,
      },
      id: location.id,
      isModalShowing: true,
      mode: "edit",
    });
  };

  hideModal = () => {
    this.setState({ isModalShowing: false });
  };

  clearModal = () => {
    this.setState({
      loading: false,
      mode: "add",
      locationData: {
        aisle: "",
        bay: "",
        level: "",
        position: "",
        active: true,
      },
    });
  };

  setInput = (e: any, name: string) => {
    this.setState((state: any) => ({
      locationData: {
        ...state.locationData,
        [name]: e.target.value,
      },
    }));
  };

  setActive = (e: any) => {
    this.setState((state: any) => ({
      locationData: {
        ...state.locationData,
        active: e.detail.checked ? true : false,
      },
    }));
  };

  submitForm = () => {
    if (this.state.mode === "add") {
      this.saveLocation();
    } else {
      this.editLocation();
    }
  };

  saveLocation = () => {
    this.setState({
      loading: true,
    });

    let data = JSON.stringify({
      aisle: this.state.locationData.aisle,
      bay: this.state.locationData.bay,
      level: this.state.locationData.level,
      position: this.state.locationData.position,
      active: this.state.locationData.active,
    });

    const locationStoreRequestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(APIs.locations.create, data, locationStoreRequestOptions)
      .then((res) => {
        this.props.onLocationInsert(res.data.data);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in saving location");
        this.setState({
          loading: false,
        });
      });
  };

  editLocation = () => {
    this.setState({
      loading: true,
    });

    let data = JSON.stringify({
      aisle: this.state.locationData.aisle,
      bay: this.state.locationData.bay,
      level: this.state.locationData.level,
      position: this.state.locationData.position,
      active: this.state.locationData.active,
    });

    const locationStoreRequestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .put(
        APIs.locations.update(this.state.id),
        data,
        locationStoreRequestOptions
      )
      .then((res) => {
        this.props.onLocationUpdate!(this.state.locationData);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in updating location");
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <IonModal
        isOpen={this.state.isModalShowing}
        onDidDismiss={this.clearModal}
        swipeToClose={false}
        keyboardClose={false}
        backdropDismiss={false}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>New location:</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={this.hideModal}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <form>
            <IonItem className="location-form-input">
              <IonLabel position="floating">Aisle:</IonLabel>
              <IonInput
                value={this.state.locationData.aisle}
                onIonChange={(e) => this.setInput(e, "aisle")}
              />
            </IonItem>
            <IonItem className="location-form-input">
              <IonLabel position="floating">Bay:</IonLabel>
              <IonInput
                value={this.state.locationData.bay}
                onIonChange={(e) => this.setInput(e, "bay")}
              />
            </IonItem>
            <IonItem className="location-form-input">
              <IonLabel position="floating">Level:</IonLabel>
              <IonInput
                value={this.state.locationData.level}
                onIonChange={(e) => this.setInput(e, "level")}
              />
            </IonItem>
            <IonItem className="location-form-input">
              <IonLabel position="floating">Position:</IonLabel>
              <IonInput
                value={this.state.locationData.position}
                onIonChange={(e) => this.setInput(e, "position")}
              />
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel>Active: </IonLabel>
              <IonToggle
                checked={!!this.state.locationData.active}
                onIonChange={this.setActive}
              />
            </IonItem>

            <IonButton
              className="location-form-save-btn"
              color="primary"
              expand="full"
              onClick={this.submitForm}
            >
              Save
            </IonButton>
          </form>

          {/* Loading */}
          <IonLoading isOpen={this.state.loading} message={"Please wait..."} />
        </IonContent>
      </IonModal>
    );
  }
}
