import { IOrder } from "../../../models/Order";
import React from "react";
import _ from "lodash";
import classnames from "classnames";
import "./ProductSizeItem.scss";
import { toast } from "react-toastify";
import { useLongPress } from "use-long-press";

interface ProductSizeItemProps {
  size: any;
  order: IOrder;
  changeOrderSize?: Function;
  disabled?: boolean;
  formType: "new" | "last";
  vat?: number;
}

/**
 * this component will render ProductSize in Products Form and show number of sizes in order
 *
 * @param product
 * @param size
 * @param order
 * @param changeOrderSize
 * @param disabled
 * @param formType
 * @param vat
 * @constructor
 */
export const ProductSizeItem: React.FC<ProductSizeItemProps> = ({
  size,
  order,
  changeOrderSize,
  disabled = false,
  formType,
  vat,
}) => {
  const orderSizeIndex = _.findIndex(order.sizes, { id: size.id });
  const sizeCode = size.code;
  const sizeStock = size.stock ?? 0;
  let lastLongClick = 0;

  const onSizeClickHandler = () => {
    if (Date.now() - lastLongClick > 250) {
      // prevent click get triggered after long press
      if (!disabled && sizeStock > 0 && changeOrderSize) {
        if (
          order.sizes[orderSizeIndex] &&
          sizeStock <= order.sizes[orderSizeIndex].count
        )
          toast.error("out of stock");
        else changeOrderSize(size, "add", vat);
      }
    }
  };

  const onLongPress = () => {
    if (changeOrderSize) {
      changeOrderSize(size, "remove");
    }
  };

  // @ts-ignore
  const longPressEvent = useLongPress(onLongPress, {
    cancelOnMovement: true,
    threshold: 250,
    detect: "both",
    onFinish: (event) => {
      lastLongClick = Date.now();
    },
  });

  if (!size.code || size.code === "null") {
    return (
      //because of bad styling of project i did add this condition that if order is saved co complete then remove background color for disabled and no stuck
      <td className="size disabled" width="10%">
        -
      </td>
    );
  }

  return (
    <td
      className={classnames("size", {
        "has-size": orderSizeIndex > -1,
        disabled: disabled,
        "no-stock": sizeStock <= 0,
      })}
      width="10%"
      onClick={onSizeClickHandler}
      {...longPressEvent}
    >
      <span className="size-code">{sizeCode}</span>
      <span className="size-count">
        {orderSizeIndex > -1
          ? formType === "new"
            ? order.sizes[orderSizeIndex].count
            : order.sizes[orderSizeIndex].pivot.count
          : ""}
      </span>
    </td>
  );
};
