import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const RouteGuard = ({ component: Component, ...rest }) => {
  const userType = useSelector((state) => state.auth?.user?.type);

  const checkUserAccess = (path) => {
    if (userType)
      switch (userType) {
        case "Stock_Controller":
          return path.includes("products") ||
            path.includes("looses") ||
            path.includes("profile") ||
            path.includes("tickets")
            ? true
            : false;
        case "Sale_Representative": {
          if (
            path.includes("products") ||
            path.includes("looses") ||
            path.includes("categories") ||
            path.includes("users") ||
            path.includes("reports")
          )
            return false;
          return true;
        }
        case "Picker": {
          if (
            path.includes("stores") ||
            path.includes("products") ||
            path.includes("looses") ||
            path.includes("categories") ||
            path.includes("users") ||
            path.includes("reports") ||
            !(path.match(/new-order\/\d+/) == null)
          )
            return false;
          return true;
        }
        case "Manager":
          return true;
        case "Admin":
          return true;
        default:
          return false;
      }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        checkUserAccess(props?.match.path) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default RouteGuard;
