// @ts-nocheck
import React, { useEffect, useState } from "react";
import "./OrdersLog.scss";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import _ from "lodash";
import moment from "moment";
import { useParams } from "react-router";
import axios from "axios";
import { APIs } from "../../services/apiService";
import { toast } from "react-toastify";

const getLabel = {
  addedSizes: "Added Sizes",
  removedSizes: "Removed Sizes",
  updatedSizes: "Updated Sizes",
};

export const OrderLog: React.FC = () => {
  const { id } = useParams();

  const [logs, setLogs] = useState<any>([]);

  const getFirstLetterOfName = (txt: any): string => {
    if (txt) if (txt.length > 0) return txt.substr(0, 1).toUpperCase();

    return "-";
  };

  const getLogs = () => {
    axios
      .get(APIs.order.logs(id))
      .then((res) => {
        setLogs(res.data.data);
      })
      .catch((err) =>
        toast.error(err.customErrorMessage ?? "can not get logs")
      );
  };

  useEffect(() => {
    getLogs();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="danger">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/orders" />
          </IonButtons>
          <IonTitle>Logs of Order: {id}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <section id="cd-timeline" className="cd-container">
          {_.map(logs, (log, index) => (
            <div key={index}>
              <div className="cd-timeline-block">
                <div className="cd-timeline-picture dark">
                  {log?.user?.avatar ? (
                    <img src={log?.user?.avatar} alt="avatar" />
                  ) : (
                    <span className="time-line-no-avatar">
                      {getFirstLetterOfName(log?.user?.name)}
                    </span>
                  )}
                </div>
                <div className="cd-timeline-content dark">
                  <h5>
                    {log?.user?.name}{" "}
                    <span className="timeline-user-type">
                      ({log?.user?.type})
                    </span>
                  </h5>
                  <p className="cd-author">
                    User{" "}
                    <span className="timeline-user-action">{log.status}</span>{" "}
                    the order.
                  </p>

                  <p className="timelineText">
                    {moment
                      .utc(log.created_at)
                      .local()
                      .format("DD/MM/YYYY HH:mm")}
                  </p>
                  {Object.keys(log.diff)?.map((key: string) => {
                    if (
                      Object.keys(getLabel).includes(key) &&
                      log.diff[key]?.length > 0
                    )
                      return (
                        <>
                          {/* @ts-ignore */}
                          <p style={{ margin: 0 }}>{getLabel[key]}</p>
                          <table className="order-return-table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Code</th>
                                <th>Type</th>
                                {key === "updatedSizes" ? (
                                  <>
                                    <th>Old Count</th>
                                    <th>New Count</th>
                                  </>
                                ) : (
                                  <th>Count</th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {_.map(log.diff[key], (changeLog, index) => (
                                <tr key={index}>
                                  <td>{changeLog.product.title}</td>
                                  <td>{changeLog.code}</td>
                                  <td>{changeLog.type}</td>
                                  {key === "updatedSizes" ? (
                                    <>
                                      <td>{changeLog.oldCount}</td>
                                      <td>{changeLog.newCount}</td>
                                    </>
                                  ) : (
                                    <td>{changeLog.count}</td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      );
                  })}
                </div>
              </div>
            </div>
          ))}
        </section>
      </IonContent>
    </IonPage>
  );
};
