import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";

/**
 * reducers
 */
import AuthReducer from "./reducers/Auth";
import TicketReducer from "./reducers/Ticket";
import OrdersReducer from "./reducers/Orders";

const appReducer = combineReducers({
  auth: AuthReducer,
  ticket: TicketReducer,
  // orders: OrdersReducer,
});

const initialState = {};

const middlewares = [thunk];

export const store = createStore(
  appReducer,
  initialState,
  compose(
    applyMiddleware(...middlewares)
    // @ts-ignore
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);
