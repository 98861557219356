import React from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonAvatar,
} from "@ionic/react";

import "./UserAddModal.scss";

import axios from "axios";
import { APIs } from "../../../services/apiService";
import { IUser } from "../../../models/User";
import { toast } from "react-toastify";

interface IUserAddModalStates {
  isModalShowing: boolean;
  loading: boolean;
  mode: "add" | "edit";
  userData: {
    id: string;
    name: string;
    type: string;
    email: string;
    username: string;
    password: string;
    avatar: any;
  };
}

interface IUserAddModalProps {
  onUserInsert?: Function;
  onUserUpdate?: Function;
}

export class UserAddModal extends React.Component<
  IUserAddModalProps,
  IUserAddModalStates
> {
  private userImageRef: React.RefObject<HTMLImageElement>;

  constructor(props: any) {
    super(props);

    this.state = {
      isModalShowing: false,
      loading: false,
      mode: "add",
      userData: {
        id: "",
        name: "",
        type: "",
        email: "",
        username: "",
        password: "",
        avatar: "",
      },
    };

    this.userImageRef = React.createRef();
  }

  showModal = () => {
    this.setState({
      isModalShowing: true,
      mode: "add",
    });
  };

  showEditModal = (user: IUser) => {
    this.setState({
      userData: {
        id: user.id,
        name: user.name,
        username: user.username,
        password: user.password,
        email: user.email,
        type: user.type,
        avatar: user.avatar,
      },
      isModalShowing: true,
      mode: "edit",
    });
  };

  hideModal = () => {
    this.setState({ isModalShowing: false });
  };

  clearModal = () => {
    this.setState({
      loading: false,
      mode: "add",
      userData: {
        id: "",
        name: "",
        type: "",
        email: "",
        username: "",
        password: "",
        avatar: "",
      },
    });
  };

  setInput = (e: any, name: string) => {
    this.setState((state) => ({
      userData: {
        ...state.userData,
        [name]: e.target.value,
      },
    }));
  };

  setPicture = (event: any) => {
    const img = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      if (this.userImageRef.current)
        this.userImageRef.current!.src = e.target!.result!.toString();
    };

    reader.readAsDataURL(img);

    this.setState((prevState) => ({
      ...prevState,
      userData: {
        ...prevState.userData,
        avatar: img,
      },
    }));
  };

  // clearPicture = () => {
  //   if (this.userImageRef.current)
  //     this.userImageRef.current!.src = '';
  //
  //   this.setState(prevState => ({
  //     ...prevState,
  //     userData: {
  //       ...prevState.userData,
  //       avatar: undefined
  //     }
  //   }));
  // }

  submitForm = () => {
    if (this.state.mode === "add") {
      this.saveUser();
    } else {
      this.editUser();
    }
  };

  saveUser = () => {
    this.setState({
      loading: true,
    });

    const userStoreRequestData = new FormData();

    userStoreRequestData.append("name", this.state.userData.name);
    userStoreRequestData.append("type", this.state.userData.type);
    userStoreRequestData.append("email", this.state.userData.email);
    userStoreRequestData.append("username", this.state.userData.username);
    userStoreRequestData.append("password", this.state.userData.password);
    userStoreRequestData.append(
      "password_confirm",
      this.state.userData.password
    );

    // set avatar
    if (this.state.userData.avatar instanceof File)
      userStoreRequestData.append("avatar", this.state.userData.avatar);

    const userStoreRequestOptions = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(APIs.users.store, userStoreRequestData, userStoreRequestOptions)
      .then((res) => {
        this.props.onUserInsert!(res.data.data);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in saving user");
        this.setState({
          loading: false,
        });
      });
  };

  editUser = () => {
    this.setState({
      loading: true,
    });

    const userStoreRequestData = new FormData();

    userStoreRequestData.append("_method", "put");
    userStoreRequestData.append("name", this.state.userData.name);
    userStoreRequestData.append("type", this.state.userData.type);
    userStoreRequestData.append("email", this.state.userData.email);
    userStoreRequestData.append("username", this.state.userData.username);

    // change password
    if (this.state.userData.password !== "" && this.state.userData.password) {
      userStoreRequestData.append("password", this.state.userData.password);
      userStoreRequestData.append(
        "password_confirm",
        this.state.userData.password
      );
    }

    // set avatar
    if (this.state.userData.avatar instanceof File)
      userStoreRequestData.append("avatar", this.state.userData.avatar);

    const userStoreRequestOptions = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(
        APIs.users.update + this.state.userData.id,
        userStoreRequestData,
        userStoreRequestOptions
      )
      .then((res) => {
        this.props.onUserUpdate!(this.state.userData);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in updating user");
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <IonModal
        isOpen={this.state.isModalShowing}
        onDidDismiss={this.clearModal}
        swipeToClose={false}
        keyboardClose={false}
        backdropDismiss={false}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>New User:</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={this.hideModal}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <form>
            <IonItem className="user-form-input">
              <IonLabel position="floating">Name:</IonLabel>
              <IonInput
                value={this.state.userData.name}
                onIonChange={(e) => this.setInput(e, "name")}
              />
            </IonItem>
            <IonItem className="user-form-input">
              <IonLabel position="floating">Username:</IonLabel>
              <IonInput
                value={this.state.userData.username}
                onIonChange={(e) => this.setInput(e, "username")}
              />
            </IonItem>
            <IonItem className="user-form-input">
              <IonLabel position="floating">Email:</IonLabel>
              <IonInput
                value={this.state.userData.email}
                onIonChange={(e) => this.setInput(e, "email")}
              />
            </IonItem>
            <IonItem className="user-form-input">
              <IonLabel position="floating">Type:</IonLabel>
              <IonSelect
                interface="alert"
                value={this.state.userData.type}
                onIonChange={(e) => this.setInput(e, "type")}
              >
                <IonSelectOption value="Stock_Controller">
                  Stock Controller
                </IonSelectOption>
                <IonSelectOption value="Picker">Picker</IonSelectOption>
                <IonSelectOption value="Sale_Representative">
                  Sale Rep
                </IonSelectOption>
                <IonSelectOption value="Admin">Admin</IonSelectOption>
                <IonSelectOption value="Manager">Manager</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem className="user-form-input">
              <IonLabel position="floating">Password:</IonLabel>
              <IonInput
                value={this.state.userData.password}
                onIonChange={(e) => this.setInput(e, "password")}
              />
            </IonItem>
            <IonItem className="user-form-input">
              <IonAvatar
                style={{
                  width: "100px",
                  height: "100px ",
                  backgroundColor: "#aaa",
                }}
              >
                {this.state.userData.avatar ? (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#aaa",
                    }}
                    src={this.state.userData.avatar}
                    alt=""
                    ref={this.userImageRef}
                  />
                ) : (
                  ""
                )}
              </IonAvatar>
              <label className="user-img-button">
                Pic
                <input type="file" onChange={this.setPicture} />
              </label>
              {/*{*/}
              {/*  this.state.userData.avatar*/}
              {/*    ?*/}
              {/*    <IonButton color="danger" onClick={this.clearPicture}>Delete</IonButton>*/}
              {/*    :*/}
              {/*    ''*/}
              {/*}*/}
            </IonItem>

            <IonButton
              className="user-form-save-btn"
              color="primary"
              expand="full"
              onClick={this.submitForm}
            >
              Save
            </IonButton>
          </form>

          {/* Loading */}
          <IonLoading isOpen={this.state.loading} message={"Please wait..."} />
        </IonContent>
      </IonModal>
    );
  }
}
