// @ts-nocheck
import React from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToggle,
  IonAvatar,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";

import "./ProductAddModal.scss";
import axios from "axios";
import _ from "lodash";
import { APIs } from "../../../services/apiService";
import { IProduct } from "../../../models/Product";
import { ICategory } from "../../../models/Category";
import { toast } from "react-toastify";
import { IProductSize } from "../../../models/ProductSize";
import { UserTypes } from "../../../models/User";

interface ProductAddModalStates {
  isModalShowing: boolean;
  loading: boolean;
  categories: ICategory[];
  mode: "add" | "edit";
  productData: IProduct;
  sizesData: Array<IProductSize>;
}

interface ProductAddModalProps {
  onProductInsert: Function;
  onProductUpdate: Function;
  userType: string;
}

const productsDetails = [
  {
    titles: ["S Code", "Stock", "Price"],
    keys: ["code", "stock", "price"],
  },
  {
    titles: ["N Code", "Stock", "Price"],
    keys: ["code", "stock", "price"],
  },
  {
    titles: ["M Code", "Stock", "Price"],
    keys: ["code", "stock", "price"],
  },
  {
    titles: ["L Code", "Stock", "Price"],
    keys: ["code", "stock", "price"],
  },
];

export class ProductAddModal extends React.Component<
  ProductAddModalProps,
  ProductAddModalStates
> {
  private productImgRef: React.RefObject<HTMLImageElement>;

  constructor(props: any) {
    super(props);

    this.state = {
      isModalShowing: false,
      loading: false,
      mode: "add",
      categories: [],
      productData: {
        id: "",
        title: "",
        description: "",
        category: {
          id: "",
          title: "",
        },
        vat: 0,
        order: 1,
        enable: 1,
        image: undefined,
      },
      sizesData: [
        {
          id: "",
          type: "small",
          code: "",
          stock: "",
          price: "",
        },
        {
          id: "",
          type: "normal",
          code: "",
          stock: "",
          price: "",
        },
        {
          id: "",
          type: "medium",
          code: "",
          stock: "",
          price: "",
        },
        {
          id: "",
          type: "large",
          code: "",
          stock: "",
          price: "",
        },
      ],
    };

    this.productImgRef = React.createRef();
  }

  componentDidMount() {
    this.getCategories();
  }

  showModal = () => {
    this.setState({ isModalShowing: true });
  };

  showEditModal = (productId: string) => {
    axios
      .get(APIs.products.productId(productId))
      .then((res) => {
        if (res.data.data) {
          const sizes = _.map(res.data.data.sizes, (size) => ({
            id: size.id,
            type: size.type,
            code: !size.code ? "" : size.code,
            stock: size.stock,
            price: size.price,
          }));
          this.setState({
            productData: res.data.data,
            sizesData: sizes,
            isModalShowing: true,
            mode: "edit",
          });
        }
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error getting products");
        return {
          data: [],
          meta: {},
        };
      });
  };

  hideModal = () => {
    this.setState({ isModalShowing: false });
  };

  clearModal = () => {
    this.setState({
      mode: "add",
      productData: {
        id: "",
        title: "",
        description: "",
        category: {
          id: "",
          title: "",
        },
        vat: 0,
        order: 1,
        enable: 1,
        image: undefined,
      },
      sizesData: [
        {
          id: "",
          type: "small",
          code: "",
          stock: "",
          price: "",
        },
        {
          id: "",
          type: "normal",
          code: "",
          stock: "",
          price: "",
        },
        {
          id: "",
          type: "medium",
          code: "",
          stock: "",
          price: "",
        },
        {
          id: "",
          type: "large",
          code: "",
          stock: "",
          price: "",
        },
      ],
    });
  };

  setInput = (e: any, name: string) => {
    this.setState((state) => ({
      productData: {
        ...state.productData,
        [name]: e.target.value,
      },
    }));
  };

  // setVatInput = (e: any, name: string) => {
  //   this.setState((state) => ({
  //     productData: {
  //       ...state.productData,
  //       [name]: e.detail.checked,
  //     },
  //   }));
  // };

  setCategoryInput = (e: any) => {
    this.setState((state) => ({
      productData: {
        ...state.productData,
        category: {
          ...state.productData.category,
          id: e.target.value,
        },
      },
    }));
  };

  setActive = (e: any) => {
    this.setState((state) => ({
      productData: {
        ...state.productData,
        enable: e.detail.checked ? 1 : 0,
      },
    }));
  };

  setVat = (e: any) => {
    this.setState((state) => ({
      productData: {
        ...state.productData,
        vat: e.detail.checked ? 1 : 0,
      },
    }));
  };

  setPicture = (event: any) => {
    const img = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.productImgRef.current!.src = e.target!.result!.toString();
    };
    reader.readAsDataURL(img);

    this.setState((prevState) => ({
      ...prevState,
      productData: {
        ...prevState.productData,
        image: img,
      },
    }));
  };

  setSizeData = (
    event: any,
    index: number,
    name: "stock" | "code" | "price"
  ) => {
    this.setState((prevState) => {
      prevState.sizesData![index][name] = event.target.value;
      return prevState;
    });
  };

  clearPicture = () => {
    this.productImgRef.current!.src = "";

    this.setState((prevState) => ({
      ...prevState,
      productData: {
        ...prevState.productData,
        image: undefined,
      },
    }));
  };

  submitForm = () => {
    if (this.state.mode === "add") {
      this.saveProduct();
    } else {
      this.editProduct();
    }
  };

  saveProduct = () => {
    this.setState({
      loading: true,
    });

    const productStoreRequestData = new FormData();

    productStoreRequestData.append("title", this.state.productData.title);
    productStoreRequestData.append(
      "description",
      this.state.productData.description!
    );
    productStoreRequestData.append(
      "enable",
      this.state.productData.enable ? "1" : "0"
    );
    productStoreRequestData.append(
      "category_id",
      this.state.productData.category.id
    );
    productStoreRequestData.append(
      "order",
      this.state.productData.order!.toString()
    );
    productStoreRequestData.append(
      "vat",
      this.state.productData.vat ? "1" : "0"
    );

    this.addProductSizesToFormRequest(productStoreRequestData);

    if (this.state.productData.image instanceof File)
      productStoreRequestData.append("image", this.state.productData.image);

    const productStoreRequestOptions = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(
        APIs.products.store,
        productStoreRequestData,
        productStoreRequestOptions
      )
      .then((res) => {
        this.props.onProductInsert!(res.data.data);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error saving product");
        this.setState({
          loading: false,
        });
      });
  };

  editProduct = () => {
    this.setState({
      loading: true,
    });

    const productUpdateRequestData = new FormData();

    productUpdateRequestData.append("_method", "put");
    productUpdateRequestData.append("title", this.state.productData.title);
    productUpdateRequestData.append(
      "description",
      this.state.productData.description!
    );
    productUpdateRequestData.append(
      "enable",
      this.state.productData.enable ? "1" : "0"
    );
    productUpdateRequestData.append(
      "category_id",
      this.state.productData.category.id
    );
    productUpdateRequestData.append(
      "order",
      this.state.productData.order!.toString()
    );
    productUpdateRequestData.append(
      "vat",
      this.state.productData.vat ? "1" : "0"
    );

    this.addProductSizesToFormRequest(productUpdateRequestData);

    if (this.state.productData.image instanceof File)
      productUpdateRequestData.append("image", this.state.productData.image);

    const productUpdateRequestOptions = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post(
        APIs.products.update + this.state.productData.id,
        productUpdateRequestData,
        productUpdateRequestOptions
      )
      .then(() => {
        this.props.onProductUpdate!(this.state.productData);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error updating product");
        this.setState({
          loading: false,
        });
      });
  };

  addProductSizesToFormRequest = (formRequest: FormData) => {
    for (let i = 0; i < 4; i++) {
      formRequest.append(`sizes[${i}][type]`, this.state.sizesData[i].type);
      formRequest.append(`sizes[${i}][code]`, this.state.sizesData[i].code);
      formRequest.append(
        `sizes[${i}][stock]`,
        this.state.sizesData![i].stock!.toString()
      );
      formRequest.append(
        `sizes[${i}][price]`,
        this.state.sizesData![i].price!.toString()
      );
      formRequest.append(`sizes[${i}][enable]`, "1");
    }
  };

  getCategories = () => {
    this.setState({
      loading: true,
    });

    axios
      .get(APIs.categories.indexAll)
      .then((res) => {
        this.setState({
          loading: false,
          categories: res.data,
        });
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error getting categories list");
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <IonModal
        isOpen={this.state.isModalShowing}
        onDidDismiss={this.clearModal}
        swipeToClose={false}
        keyboardClose={false}
        backdropDismiss={false}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>New Product:</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={this.hideModal}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <form>
            <IonItem className="product-form-input">
              <IonLabel position="floating">Title:</IonLabel>
              <IonInput
                value={this.state.productData.title}
                onIonChange={(e) => this.setInput(e, "title")}
                disabled={
                  ![UserTypes.admin, UserTypes.manager].includes(
                    this.props.userType
                  )
                }
              />
            </IonItem>
            <IonItem className="product-form-input">
              <IonLabel position="floating">Category:</IonLabel>
              <IonSelect
                interface="alert"
                value={this.state.productData.category.id}
                onIonChange={this.setCategoryInput}
                disabled={
                  ![UserTypes.admin, UserTypes.manager].includes(
                    this.props.userType
                  )
                }
              >
                {this.state.categories.map((cat) => (
                  <IonSelectOption key={cat.id} value={cat.id}>
                    {cat.title}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem className="product-form-input">
              <IonLabel position="floating">Order:</IonLabel>
              <IonInput
                type="number"
                value={this.state.productData.order}
                onIonChange={(e) => this.setInput(e, "order")}
                disabled={
                  ![UserTypes.admin, UserTypes.manager].includes(
                    this.props.userType
                  )
                }
              />
            </IonItem>
            <IonItem className="product-form-input">
              <IonLabel position="floating">Description:</IonLabel>
              <IonTextarea
                value={this.state.productData.description}
                onIonChange={(e) => this.setInput(e, "description")}
                disabled={
                  ![UserTypes.admin, UserTypes.manager].includes(
                    this.props.userType
                  )
                }
              />
            </IonItem>
            {/* Img Filed */}
            <IonItem className="store-form-input">
              <IonAvatar
                style={{
                  width: "100px",
                  height: "100px ",
                  backgroundColor: "#aaa",
                }}
              >
                {this.state.productData.image ? (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#aaa",
                    }}
                    src={this.state.productData.image}
                    alt=""
                    ref={this.productImgRef}
                  />
                ) : (
                  ""
                )}
              </IonAvatar>
              <label className="product-img-button">
                Pic
                <input
                  type="file"
                  onChange={this.setPicture}
                  disabled={
                    ![UserTypes.admin, UserTypes.manager].includes(
                      this.props.userType
                    )
                  }
                />
              </label>
              {/*{*/}
              {/*  this.state.productData.image*/}
              {/*    ?*/}
              {/*    <IonButton color="danger" onClick={this.clearPicture}>Delete</IonButton>*/}
              {/*    :*/}
              {/*    ''*/}
              {/*}*/}
            </IonItem>
            {/* END :: Img Filed */}
            <IonItem className="store-form-input">
              <IonLabel>Active: </IonLabel>
              <IonToggle
                checked={!!this.state.productData.enable}
                disabled={
                  ![UserTypes.admin, UserTypes.manager].includes(
                    this.props.userType
                  )
                }
                onIonChange={this.setActive}
              />
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel>VAT: </IonLabel>
              <IonToggle
                checked={!!this.state.productData.vat}
                disabled={
                  ![UserTypes.admin, UserTypes.manager].includes(
                    this.props.userType
                  )
                }
                onIonChange={this.setVat}
              />
            </IonItem>

            {productsDetails.map((detail, index) => {
              return (
                <IonGrid key={detail.titles[index] + index}>
                  <IonRow>
                    {detail.keys.map((key: any, i) => {
                      return (
                        <IonCol
                          size="4"
                          key={`col-${detail.titles[index]}${index}${i}`}
                        >
                          <IonItem className="product-form-input">
                            <IonLabel position="floating">
                              {detail.titles[i]}:
                            </IonLabel>

                            <IonInput
                              // @ts-ignore
                              value={
                                this.state.sizesData[index]
                                  ? this.state.sizesData![index][key]
                                  : ""
                              }
                              onIonChange={(e) =>
                                this.setSizeData(e, index, key)
                              }
                              type="number"
                              disabled={
                                key !== "stock" &&
                                ![UserTypes.admin, UserTypes.manager].includes(
                                  this.props.userType
                                )
                              }
                            />
                          </IonItem>
                        </IonCol>
                      );
                    })}
                  </IonRow>
                </IonGrid>
              );
            })}

            <IonButton
              className="product-form-save-btn"
              color="primary"
              expand="full"
              onClick={this.submitForm}
            >
              Save
            </IonButton>
          </form>

          {/* Loading */}
          <IonLoading isOpen={this.state.loading} message={"Please wait..."} />
        </IonContent>
      </IonModal>
    );
  }
}
