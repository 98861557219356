import { IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import _ from "lodash";

export const LinearChart = (props: any) => {
  const { item } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.data);
  }, [props]);

  return (
    <div className="w-100 h-100 p-2">
      <IonLabel
        className="pl-2 font-weight-bold text-success cell-title"
        position="floating"
      >
        {item.title}
      </IonLabel>
      <ResponsiveContainer width="100%" height="100%" className="report-linear">
        <LineChart
          data={data}
          margin={{ top: 25, right: 50, left: 15, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" angle={-8} />
          <YAxis
            allowDataOverflow={false}
            domain={[0, +_.maxBy(props.data, (o: any) => +o.price)?.price]}
          />
          <Tooltip />

          <Line type="monotone" dataKey="price" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
