import { Actions, IAction } from "../actionTypes";

const initialState = {
  newTicketCount: 0,
};

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case Actions.TICKET.SET_NEW_TICKET_COUNT:
      return {
        ...state,
        newTicketCount: action.payload,
      };
    default:
      return state;
  }
};
