import React from "react";
import { Avatar } from "../../Avatar/Avatar";
import { IonItem, IonLabel, IonSkeletonText } from "@ionic/react";

import "./LooseItem.scss";

interface LooseItemProps {
  title?: string;
  price?: number | string;
  isLoader?: boolean;
}

export const LooseItem: React.FC<LooseItemProps> = ({
  title,
  price,
  isLoader,
}) => {
  if (isLoader) {
    return (
      <IonItem className="loose-item">
        <IonSkeletonText
          animated
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
        <IonLabel className="loose-item-info">
          <IonSkeletonText
            animated
            style={{ width: "40%", height: "16px", marginBottom: "5px" }}
          />
          <IonSkeletonText animated style={{ width: "100%", height: "12px" }} />
        </IonLabel>
      </IonItem>
    );
  }

  return (
    <IonItem className="loose-item">
      <Avatar label="L" />
      <IonLabel className="loose-item-info">
        <h3>{title}</h3>
        <p>£{price}</p>
      </IonLabel>
    </IonItem>
  );
};
