import React from 'react';
import moment from 'moment';
import {Avatar} from '../../Avatar/Avatar';
import {IonItem, IonLabel, IonSkeletonText} from '@ionic/react';
import classnames from 'classnames';


import './LastOrderItem.scss';
import {ORDER_STATUS} from '../../../models/Order';

interface OrderItemProps {
  routerLink?: string;
  avatar?: string;
  date?: string;
  status?: number;
  isLoader?: boolean;
  count?: number | string;
}


export const LastOrderItem: React.FC<OrderItemProps> = ({
                                                          date,
                                                          isLoader,
                                                          status,
                                                          routerLink,
                                                          count,
                                                          avatar
                                                        }) => {

  if (isLoader) {
    return (
      <IonItem className="last-order-item">
        <IonSkeletonText animated style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
        <IonLabel className="last-order-item-info">
          <IonSkeletonText animated style={{width: '40%', height: '16px', marginBottom: '5px'}}/>
          <IonSkeletonText animated style={{width: '100%', height: '12px'}}/>
        </IonLabel>
      </IonItem>
    )
  }

  return (
    <IonItem routerLink={routerLink} className="last-order-item">
      <Avatar label={avatar ?? 'o'}/>
      <IonLabel className="last-order-item-info">
        <h3>
          {count} - {' '}
          <i
            className={classnames({
              'pending-order-status': status == ORDER_STATUS.pending,
              'complete-order-status': status == ORDER_STATUS.complete
            })}
          >
            ({status == ORDER_STATUS.complete ? 'complete' : 'pending'})</i>
        </h3>
        <p>{date ? moment(date).format('ll') : ''}</p>
      </IonLabel>
    </IonItem>
  );
}
