import React from "react";
import { IonApp } from "@ionic/react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import Firebase, { FirebaseContext } from "./firebase";
import { store } from "./store/store";
// import OneSignal from "react-onesignal";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "react-toastify/dist/ReactToastify.css";

/* Theme variables */
import "./theme/base.scss";
import "./theme/variables.css";

/** bootstrap utilitis **/
import AppRouter from "./AppRouter";
import "./theme/base.scss";
import "./theme/utilities.css";

const App: React.FC = () => {
  return (
    <IonApp>
      <FirebaseContext.Provider value={new Firebase()}>
        <Provider store={store}>
          <AppRouter />
        </Provider>
        <ToastContainer
          position="bottom-center"
          closeOnClick
          pauseOnHover={false}
          hideProgressBar
        />
      </FirebaseContext.Provider>
    </IonApp>
  );
};

export default App;
