// @ts-nocheck
import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonLoading,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import axios from "axios";
import { APIs } from "../../../../services/apiService";

import "./AddDailyProductModal.scss";
import { dailyProductColumns } from "../../../../constants/dailyProduction";
import { toast } from "react-toastify";

const defaultDailyItem = {
  product_size_id: "",
  batch_number: "",
  quantity: null,
  weight: null,
  production_date: "",
  expiration_date: "",
};

const getColType = {
  product_size_id: "number",
  batch_number: "number",
  quantity: "number",
  weight: "number",
  production_date: "date",
  expiration_date: "date",
};

export const AddDailyProductModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(props.displayModal);
  const [dailyItems, setDailyItems] = useState([
    {
      ...defaultDailyItem,
    },
  ]);

  const toggleModal = () => {
    props.toggleModal();
  };

  const updateData = (e: any, index: number, key: string) => {
    setDailyItems((preItems) => {
      let items = [...preItems];
      items[index][key] = e.target.value;
      return items;
    });
  };

  const getProductIds = () => {
    return dailyItems.map(async (item) => {
      return await axios
        .get(APIs["product-size"].searchByCode(item.product_size_id))
        .then((res) => {
          if (res.data.data?.length > 0)
            return {
              ...item,
              product_size_id: res.data.data[0].id,
            };
          else {
            setLoading(false);
            toast.error(item.product_size_id + " is not a valid code");
            return {};
          }
        });
    });
  };

  const postDailyProducts = () => {
    setLoading(true);
    try {
      Promise.all(getProductIds()).then((productItems) => {
        axios
          .post(APIs["daily-productions"].bulkCreate, { items: productItems })
          .then((res) => {
            setLoading(false);
            props.getDailyList();
            toast.success("Daily products has been saved");
            toggleModal();
          });
      });
    } catch (e) {
      setLoading(false);
      // @ts-ignore
      toast.error(e?.customErrorMessage ?? "ERROR getting products");
    }
  };

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Add Daily Production:</IonTitle>
          <IonButtons slot="end">
            <IonButton
              className="text-success font-weight-bold"
              onClick={postDailyProducts}
            >
              Save
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={toggleModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonButtons>
          <IonButton
            onClick={() => {
              setDailyItems((items) => [...items, { ...defaultDailyItem }]);
            }}
          >
            Add New Row <IonIcon icon={addCircleOutline} size="large" />
          </IonButton>
        </IonButtons>

        <div style={{ overflowX: "auto" }}>
          <table className="product-update-table">
            <thead>
              <tr className="product-update-form-size-title">
                {dailyProductColumns.map((col) => (
                  <th>
                    <h2>{col.label}</h2>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dailyItems?.map((row: any, index) => (
                <tr key={"row" + index}>
                  {Object.keys(row).map((col, i) => {
                    return (
                      <td key={"row" + index + "col" + i}>
                        <IonInput
                          value={row[col]}
                          onIonChange={(e) => updateData(e, index, col)}
                          type={getColType[col]}
                        />
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Loading */}
        <IonLoading isOpen={loading} message={"Please wait..."} />
      </IonContent>
    </IonModal>
  );
};
