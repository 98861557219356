import React, { useState } from "react";
import "./LoosePanel.scss";
import _ from "lodash";
import { ILoose } from "../../../models/Loose";
import { IOrder } from "../../../models/Order";
import { OrderLooseItem } from "../OrderLooseItem/OrderLooseItem";
import { OrderLooseModal } from "../OrderLooseModal/OrderLooseModal";

interface ILoosePanelProps {
  order: IOrder;
  looses: ILoose[];
  changeOrderLoose?: Function;
  setOrderLoose?: Function;
  isLoggedForm?: boolean;
}

export const LoosePanel: React.FC<ILoosePanelProps> = ({
  order,
  looses,
  changeOrderLoose,
  isLoggedForm,
  setOrderLoose,
}) => {
  const [looseModal, setLooseModal] = useState({
    visiblie: false,
    loose: {},
  });

  const showLooseModal = (loose: ILoose) => {
    setLooseModal({ visiblie: true, loose: loose });
  };

  const hideLooseModal = () => {
    setLooseModal({ visiblie: false, loose: {} });
  };

  const changeOrderLooseHandler = (
    loose: ILoose,
    actionType: "add" | "remove"
  ) => {
    if (changeOrderLoose) changeOrderLoose(loose, actionType);
  };

  return (
    <>
      <table className="looses-table w-100">
        <thead>
          <tr>
            <th>Loose (5Kg)</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {_.map(looses, (loose) => (
            <OrderLooseItem
              key={loose.id}
              order={order}
              loose={loose}
              changeOrderLoose={changeOrderLooseHandler}
              showLooseModal={showLooseModal}
            />
          ))}
        </tbody>
      </table>

      {!isLoggedForm && setOrderLoose && (
        <OrderLooseModal
          open={looseModal.visiblie}
          loose={looseModal.loose}
          orderLooses={order.looses}
          storeId={order.store?.id}
          changeOrderLoose={changeOrderLooseHandler}
          onDidDismiss={hideLooseModal}
          setOrderLoose={setOrderLoose}
        />
      )}
    </>
  );
};
