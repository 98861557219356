import _ from "lodash";
import React from "react";
import { IOrder } from "../../../models/Order";
import { PRODUCT_SIZES } from "../../../models/ProductSize";
import "./PickerDetailsForm.scss";

interface PickerDetailsFormProps {
  order: IOrder;
  allProductList: Array<any>;
  productCounts: any;
}

export const PickerDetailsForm: React.FC<PickerDetailsFormProps> = ({
  order,
  allProductList,
  productCounts,
}) => {
  const groupedSizes = _.chain(order.sizes).groupBy("type").value();
  const groupedSizesCounts = _.chain(groupedSizes)
    .map((orderSizes, type) => {
      orderSizes.forEach((orderSize) => {
        allProductList.forEach((category) => {
          let product = category.products.find(
            // @ts-ignore
            (product) => orderSize.product_id === product.id
          );
          if (product) {
            // @ts-ignore
            orderSize["categoryOrder"] = category?.order;
            // @ts-ignore
            orderSize["productOrder"] = product?.order;
            // @ts-ignore
            orderSize["columnNumber"] = category?.column_number;
          }
        });
      });

      orderSizes.sort(
        // @ts-ignore
        (a, b): any =>
          // @ts-ignore
          (a.columnNumber as number) - (b.columnNumber as number) ||
          // @ts-ignore
          (a.categoryOrder as number) - (b.categoryOrder as number) ||
          // @ts-ignore
          a.productOrder - b.productOrder
      );

      return {
        type: type,
        count: _.chain(orderSizes)
          .map("pivot.count")
          .reduce((sum, c) => sum + c)
          .value(),
      };
    })
    .value();

  (() => {
    if (order?.halves_data?.length) {
      order?.halves_data?.forEach((hlf) => {
        allProductList?.forEach((category) => {
          let product = category.products?.find(
            // @ts-ignore
            (product) => hlf.product_id === product.id
          );
          if (product) {
            // @ts-ignore
            hlf["categoryOrder"] = category.order;
            // @ts-ignore
            hlf["productOrder"] = product?.order;
            // @ts-ignore
            hlf["columnNumber"] = category?.column_number;
          }
        });
      });

      order?.halves_data?.sort(
        (a, b): any =>
          // @ts-ignore
          (a.columnNumber as number) - (b.columnNumber as number) ||
          // @ts-ignore
          (a.categoryOrder as number) - (b.categoryOrder as number) ||
          // @ts-ignore
          a.productOrder - b.productOrder
      );
    }
  })();

  (() => {
    if (order?.looses?.length) {
      order?.looses?.sort(
        (a, b): any =>
          // @ts-ignore
          (a.order as number) - (b.order as number)
      );
    }
  })();

  const smallSizes = groupedSizes[PRODUCT_SIZES.small] ?? [];
  const normalSizes = groupedSizes[PRODUCT_SIZES.normal] ?? [];
  const mediumSizes = groupedSizes[PRODUCT_SIZES.medium] ?? [];
  const largeSizes = groupedSizes[PRODUCT_SIZES.large] ?? [];
  const looses = order.looses ?? [];
  const halves = order.halves ?? [];

  const totalSmall =
    _.find(groupedSizesCounts, (size: any) => size.type === PRODUCT_SIZES.small)
      ?.count ?? 0;
  const totalMedium =
    _.find(
      groupedSizesCounts,
      (size: any) => size.type === PRODUCT_SIZES.medium
    )?.count ?? 0;
  const totalNormal =
    _.find(
      groupedSizesCounts,
      (size: any) => size.type === PRODUCT_SIZES.normal
    )?.count ?? 0;
  const totalLarge =
    _.find(groupedSizesCounts, (size: any) => size.type === PRODUCT_SIZES.large)
      ?.count ?? 0;
  const totalLoose = _.chain(order.looses).map("pivot.quantity").sum().value();
  // TODO : this is not real total! total will be readt from backend later.
  const totalHalf = halves.length;
  // const totalLoose = looses.length;
  const totalReturns = order.returns.length;

  let totalSizeCount = 0;
  // @ts-ignore
  _.each(groupedSizesCounts, (size) => {
    totalSizeCount += size.count;
  });

  const total = totalSizeCount + totalHalf;

  return (
    <>
      {/* Normal */}
      {totalNormal > 0 && (
        <>
          <table className="picker-table">
            <thead>
              <tr>
                <th className="picker-form-size-title" colSpan={2}>
                  Normal
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(normalSizes, (size, index) => (
                <tr key={index}>
                  <td className="picker-form-product-title">
                    <span className="size-count">{size.pivot.count}</span>
                    {"     " + size.product?.title}
                  </td>
                  <td className="picker-form-product-code-and-count">
                    <div className="order-product-details-container">
                      <span className="product-code">{size.code}</span>
                      <span className="product-count">{size.pivot.count}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {/*Small*/}
      {totalSmall > 0 && (
        <>
          <table className="picker-table">
            <thead>
              <tr>
                <th className="picker-form-size-title" colSpan={2}>
                  Small
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(smallSizes, (size, index) => (
                <tr key={index}>
                  <td className="picker-form-product-title">
                    <span className="size-count">{size.pivot.count}</span>
                    {size.product?.title}
                  </td>
                  <td className="picker-form-product-code-and-count">
                    <div className="order-product-details-container">
                      <span className="product-code">{size.code}</span>
                      <span className="product-count">{size.pivot.count}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {/* Medium */}
      {totalMedium > 0 && (
        <>
          <table className="picker-table">
            <thead>
              <tr>
                <th className="picker-form-size-title" colSpan={2}>
                  Medium
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(mediumSizes, (size, index) => (
                <tr key={index}>
                  <td className="picker-form-product-title">
                    <span className="size-count">{size.pivot.count}</span>
                    {size.product?.title}
                  </td>
                  <td className="picker-form-product-code-and-count">
                    <div className="order-product-details-container">
                      <span className="product-code">{size.code}</span>
                      <span className="product-count">{size.pivot.count}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {/* Large */}
      {totalLarge > 0 && (
        <>
          <table className="picker-table">
            <thead>
              <tr>
                <th className="picker-form-size-title" colSpan={2}>
                  Large
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(largeSizes, (size, index) => (
                <tr key={index}>
                  <td className="picker-form-product-title">
                    <span className="size-count">{size.pivot.count}</span>
                    {size.product?.title}
                  </td>
                  <td className="picker-form-product-code-and-count">
                    <div className="order-product-details-container">
                      <span className="product-code">{size.code}</span>
                      <span className="product-count">{size.pivot.count}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {/* Loose */}
      {totalLoose > 0 && (
        <>
          <table className="picker-table">
            <thead>
              <tr>
                <th className="picker-form-size-title" colSpan={2}>
                  Looses
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(looses, (size, index) => (
                <tr key={index}>
                  <td className="picker-form-product-title">
                    <span className="size-count">{size.pivot.quantity}</span>
                    {size.title}
                  </td>
                  <td className="picker-form-product-code-and-count">
                    <div className="order-product-details-container">
                      <span className="product-count">
                        {size.pivot.quantity}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {/* halves */}
      {totalHalf > 0 && (
        <>
          <table className="picker-table">
            <thead>
              <tr>
                <th className="picker-form-size-title" colSpan={2}>
                  Half x6
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(order.halves_data, (size, index) => (
                <tr key={index}>
                  <td className="picker-form-product-title">
                    <span className="size-count">{1}</span>
                    {
                      allProductList
                        .map((list) => list.products)
                        .flat(1)
                        .find((product) => product.id === size.product_id)
                        ?.title
                    }
                  </td>
                  <td className="picker-form-product-code-and-count">
                    <div className="order-product-details-container">
                      <span className="product-code">{size.code}</span>
                      <span className="product-count">1</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {/* returns */}
      {totalReturns > 0 && (
        <>
          <table className="picker-table">
            <thead>
              <tr>
                <th className="picker-form-size-title" colSpan={2}>
                  Returns
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(order.returns, (returnItem, index) => (
                <tr key={index}>
                  <td className="picker-form-product-title">
                    <span className="size-count">{returnItem.count}</span>
                    {returnItem.code}
                  </td>
                  <td className="picker-form-product-code-and-count">
                    <div className="order-product-details-container">
                      <span className="product-count">{returnItem.count}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
