import PouchDB from 'pouchdb';
import PouchDb_Mango from 'pouchdb-find';
import {v4 as uuid} from 'uuid';
import {IOrder} from '../models/Order';

PouchDB.plugin(PouchDb_Mango);

export const DB = new PouchDB('roynut');

// replicate to pouchdb-server if in development mode
if (process.env.NODE_ENV === 'development') {
  const remoteDB = new PouchDB('http://localhost:5448/roynut');
  DB.sync(remoteDB, {
    live: true
  });
  console.warn('Pouch DB is in Development mode');
}

/**
 * initialize db and database types
 */
DB.createIndex({
  index: {fields: ['db_doc_type']}
});

export const DBTypes = {
  order: 'order'
}

/* all doc methods */

/**
 * get all draft orders
 * @param userId
 */
export const getAllDraftOrders = async (userId: any): Promise<Array<any>> => {
  try {
    return await DB.find({
      selector: {
        doc_type: {$eq: DBTypes.order},
        user_id: userId
      }
    }).then(data => data.docs);
  } catch (e) {
    throw e;
  }
}

/**
 * get draft order by ID
 * @param id
 */
export const getDraftOrderById = async (id: string | number): Promise<any> => {
  try {
    return await DB.get(id.toString());
  } catch (e) {
    throw e;
  }
}

/**
 * save order as draft
 * @param order
 * @param userId
 * @param id
 */
export const saveOrderAsDraft = async (order: IOrder, userId: any, id?: any): Promise<any> => {
  try {
    const _uuid = id ? id : uuid();
    return await DB.put({
      _id: _uuid,
      doc_type: DBTypes.order,
      data: {
        ...order,
        id: _uuid
      },
      user_id: userId
    });
  } catch (e) {
    throw e;
  }
}

/**
 * delete draft order by Id
 * @param id
 */
export const deleteDraftOrderById = async (id: string | number): Promise<any> => {
  try {
    return await DB.get(id.toString())
      .then(doc => {
        return DB.remove(doc._id, doc._rev);
      });
  } catch (e) {
    // throw e;
  }
}
