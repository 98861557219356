import React from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonToggle,
  IonLoading,
  IonSelectOption,
  IonSelect,
} from "@ionic/react";

import "./StoreAddModal.scss";
import {
  IStore,
  STORE_MESSAGE_STATUS,
  STORE_PAYMENT_TERM,
} from "../../../models/Store";
import axios from "axios";
import { APIs } from "../../../services/apiService";
import { toast } from "react-toastify";

interface StoreAddModalStates {
  isModalShowing: boolean;
  loading: boolean;
  mode: "add" | "edit";
  storeData: IStore;
}

interface StoreAddModalProps {
  onStoreInsert: Function;
  onStoreUpdate: Function;
}

export class StoreAddModal extends React.Component<
  StoreAddModalProps,
  StoreAddModalStates
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isModalShowing: false,
      loading: false,
      mode: "add",
      storeData: {
        id: "",
        name: "",
        description: "",
        active: 1,
        address: {
          location: "",
        },
        message: "",
        image: undefined,
        payment_term: 1,
        phone_number: "",
        visit_period: "",
        ac: "",
        colour: "",
        discount: 0,
        message_status: 1,
      },
    };
  }

  showModal = () => {
    this.setState({ isModalShowing: true });
  };

  showEditModal = (store: IStore) => {
    this.setState({
      storeData: {
        ...store,
      },
      isModalShowing: true,
      mode: "edit",
    });
  };

  hideModal = () => {
    this.setState({ isModalShowing: false });
  };

  clearModal = () => {
    this.setState({
      loading: false,
      mode: "add",
      storeData: {
        id: "",
        name: "",
        description: "",
        active: 1,
        address: {
          location: "",
        },
        message: "",
        image: "",
        payment_term: 1,
        phone_number: "",
        visit_period: "",
        ac: "",
        colour: "",
        discount: 0,
        message_status: 1,
      },
    });
  };

  setInput = (e: any, name: string) => {
    this.setState((state) => ({
      storeData: {
        ...state.storeData,
        [name]: e.target.value,
      },
    }));
  };

  setLocation = (e: any) => {
    this.setState((state) => ({
      storeData: {
        ...state.storeData,
        address: {
          location: e.target.value,
        },
      },
    }));
  };

  setActive = (e: any) => {
    this.setState((state) => ({
      storeData: {
        ...state.storeData,
        active: e.detail.checked ? 1 : 0,
      },
    }));
  };

  submitForm = () => {
    if (this.state.mode === "add") {
      this.saveStore();
    } else {
      this.editStore();
    }
  };

  saveStore = () => {
    this.setState({
      loading: true,
    });

    const storeStoreRequestData = {
      ...this.state.storeData,
      image: this.state.storeData.image
        ? this.state.storeData.image
        : undefined,
      address: JSON.stringify(this.state.storeData.address),
    };
    axios
      .post(APIs.stores.store, storeStoreRequestData)
      .then((res) => {
        this.props.onStoreInsert!(res.data.data);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in storing store");
        this.setState({
          loading: false,
        });
      });
  };

  editStore = () => {
    this.setState({
      loading: true,
    });

    const storeStoreRequestData = {
      ...this.state.storeData,
      image: this.state.storeData.image
        ? this.state.storeData.image
        : undefined,
      address: JSON.stringify(this.state.storeData.address),
    };

    axios
      .put(APIs.stores.update + this.state.storeData.id, storeStoreRequestData)
      .then((res) => {
        this.props.onStoreUpdate!(this.state.storeData);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in updating store");
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <IonModal
        isOpen={this.state.isModalShowing}
        onDidDismiss={this.clearModal}
        swipeToClose={false}
        keyboardClose={false}
        backdropDismiss={false}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>New Store:</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={this.hideModal}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <form>
            <IonItem className="store-form-input">
              <IonLabel position="floating">Name:</IonLabel>
              <IonInput
                value={this.state.storeData.name}
                onIonChange={(e) => this.setInput(e, "name")}
              />
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel position="floating">Address:</IonLabel>
              <IonTextarea
                className="store-address"
                value={this.state.storeData.address.location}
                onIonChange={this.setLocation}
              ></IonTextarea>
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel position="floating">Description:</IonLabel>
              <IonTextarea
                className="store-description"
                value={this.state.storeData.description}
                onIonChange={(e) => this.setInput(e, "description")}
              ></IonTextarea>
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel position="floating">Message:</IonLabel>
              <IonTextarea
                className="store-message"
                value={this.state.storeData.message}
                onIonChange={(e) => this.setInput(e, "message")}
              ></IonTextarea>
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel position="floating">Message Status:</IonLabel>
              <IonSelect
                value={this.state.storeData.message_status}
                onIonChange={(e) => this.setInput(e, "message_status")}
                interface="popover"
              >
                <IonSelectOption value={STORE_MESSAGE_STATUS.Always}>
                  Always
                </IonSelectOption>
                <IonSelectOption value={STORE_MESSAGE_STATUS.Enable}>
                  Enable
                </IonSelectOption>
                <IonSelectOption value={STORE_MESSAGE_STATUS.Disable}>
                  Disable
                </IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel position="floating">Phone number:</IonLabel>
              <IonInput
                value={this.state.storeData.phone_number}
                onIonChange={(e) => this.setInput(e, "phone_number")}
              />
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel position="floating">Payment term:</IonLabel>
              <IonSelect
                value={this.state.storeData.payment_term}
                onIonChange={(e) => this.setInput(e, "payment_term")}
                interface="popover"
              >
                <IonSelectOption value={STORE_PAYMENT_TERM.CashOnDelivery}>
                  Cash on Delivery
                </IonSelectOption>
                <IonSelectOption value={STORE_PAYMENT_TERM.Account}>
                  Account
                </IonSelectOption>
                <IonSelectOption value={STORE_PAYMENT_TERM.oneInOneOut}>
                  One in one iut
                </IonSelectOption>
              </IonSelect>
              {/*<IonInput*/}
              {/*  value={this.state.storeData.payment_term}*/}
              {/*  onIonChange={(e) => this.setInput(e, 'payment_term')}*/}
              {/*/>*/}
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel position="floating">Discount:</IonLabel>
              <IonInput
                value={this.state.storeData.discount}
                onIonChange={(e) => this.setInput(e, "discount")}
                type="number"
              />
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel position="floating">Visit period:</IonLabel>
              <IonInput
                value={this.state.storeData.visit_period}
                onIonChange={(e) => this.setInput(e, "visit_period")}
              />
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel position="floating">AC:</IonLabel>
              <IonInput
                value={this.state.storeData.ac}
                onIonChange={(e) => this.setInput(e, "ac")}
              />
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel position="floating">Colour:</IonLabel>
              <IonInput
                value={this.state.storeData.colour}
                onIonChange={(e) => this.setInput(e, "colour")}
              />
            </IonItem>
            <IonItem className="store-form-input">
              <IonLabel>Active: </IonLabel>
              <IonToggle
                checked={!!this.state.storeData.active}
                onIonChange={this.setActive}
              />
            </IonItem>

            <IonButton
              className="store-form-save-btn"
              color="primary"
              expand="full"
              onClick={this.submitForm}
            >
              Save
            </IonButton>
          </form>

          {/* Loading */}
          <IonLoading isOpen={this.state.loading} message={"Please wait..."} />
        </IonContent>
      </IonModal>
    );
  }
}
