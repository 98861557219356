import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
  IonText,
} from "@ionic/react";
import React from "react";
import { downloadOutline, statsChartOutline } from "ionicons/icons";
import "./Reports.scss";
import { APIs } from "../../services/apiService";

export const Reports: React.FC = () => {
  return (
    <IonPage className="orders-page">
      <IonHeader>
        <IonToolbar color="danger">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dashboard" />
          </IonButtons>
          <IonTitle>Reports</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem routerLink={`/reports/stores`}>
          <IonIcon icon={statsChartOutline} />
          <IonText className="text">Stores</IonText>
        </IonItem>
        <IonItem routerLink={`/reports/returns`}>
          <IonIcon icon={statsChartOutline} />
          <IonText className="text">Returns</IonText>
        </IonItem>
        {/* <IonItem routerLink={`/reports/productsPrices`}>
          <IonIcon icon={statsChartOutline} />
          <IonText className="text">Products Prices</IonText>
        </IonItem> */}
        <IonItem routerLink={`/reports/productsPricesV2`}>
          <IonIcon icon={statsChartOutline} />
          <IonText className="text">Products Prices</IonText>
        </IonItem>
        <IonItem routerLink={`/reports/productsStocks`}>
          <IonIcon icon={statsChartOutline} />
          <IonText className="text">Products Stock</IonText>
        </IonItem>
        <IonItem>
          <a
            className="download-link"
            type="text/csv"
            download="products-prices"
            href={APIs.reports.productsPricesExport}
          >
            <IonIcon icon={downloadOutline} className="icon" />
            <IonText className="text">Products Prices</IonText>
          </a>
        </IonItem>
        <IonItem>
          <a
            className="download-link"
            type="text/csv"
            download="products-prices"
            href={APIs.reports.productsStockExport}
          >
            <IonIcon icon={downloadOutline} className="icon" />
            <IonText className="text">Products Stock</IonText>
          </a>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};
