import React from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonTitle,
  IonToolbar,
  IonToggle,
} from "@ionic/react";
import "./LooseAddModal.scss";
import { ILoose } from "../../../models/Loose";
import axios from "axios";
import { APIs } from "../../../services/apiService";
import { toast } from "react-toastify";
import { UserTypes } from "../../../models/User";

interface LooseAddModalProps {
  onLooseInsert: Function;
  onLooseUpdate: Function;
  userType: string;
}

interface LooseAddModalStates {
  isModalShowing: boolean;
  loading: boolean;
  mode: "add" | "edit";
  looseData: ILoose;
}

export class LooseAddModal extends React.Component<
  LooseAddModalProps,
  LooseAddModalStates
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isModalShowing: false,
      loading: false,
      mode: "add",
      looseData: {
        id: "",
        title: "",
        price: "",
        order: 0,
        vat: 0,
      },
    };
  }

  showModal = () => {
    this.setState({ isModalShowing: true });
  };

  showEditModal = (loose: ILoose) => {
    this.setState({
      looseData: {
        ...loose,
      },
      isModalShowing: true,
      mode: "edit",
    });
  };

  hideModal = () => {
    this.setState({ isModalShowing: false });
  };

  clearModal = () => {
    this.setState({
      loading: false,
      mode: "add",
      looseData: {
        id: "",
        title: "",
        price: "",
        order: 0,
        vat: 0,
      },
    });
  };

  setInput = (e: any, name: string) => {
    this.setState((state) => ({
      looseData: {
        ...state.looseData,
        [name]: e.target.value,
      },
    }));
  };

  setVat = (e: any) => {
    this.setState((state) => ({
      looseData: {
        ...state.looseData,
        vat: e.detail.checked ? 1 : 0,
      },
    }));
  };

  submitForm = () => {
    if ([UserTypes.admin, UserTypes.manager].includes(this.props.userType))
      if (this.state.mode === "add") {
        this.saveLoose();
      } else {
        this.editLoose();
      }
    else toast.info("Permission Denied");
  };

  saveLoose = () => {
    this.setState({
      loading: true,
    });

    const looseStoreRequestData = {
      ...this.state.looseData,
    };

    axios
      .post(APIs.looses.store, looseStoreRequestData)
      .then((res) => {
        this.props.onLooseInsert!(res.data.data);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in adding loose");
        this.setState({
          loading: false,
        });
      });
  };

  editLoose = () => {
    this.setState({
      loading: true,
    });

    const looseUpdateRequestData = {
      ...this.state.looseData,
    };

    axios
      .put(APIs.looses.update(this.state.looseData.id), looseUpdateRequestData)
      .then(() => {
        this.props.onLooseUpdate!(this.state.looseData);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in updating loose");
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <IonModal
        isOpen={this.state.isModalShowing}
        onDidDismiss={this.clearModal}
        swipeToClose={false}
        keyboardClose={false}
        backdropDismiss={false}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>New Loose:</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={this.hideModal}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <form>
            <IonItem className="loose-form-input">
              <IonLabel position="floating">Title:</IonLabel>
              <IonInput
                value={this.state.looseData.title}
                onIonChange={(e) => this.setInput(e, "title")}
                disabled={
                  ![UserTypes.admin, UserTypes.manager].includes(
                    this.props.userType
                  )
                }
              />
            </IonItem>
            <IonItem className="loose-form-input">
              <IonLabel position="floating">Price:</IonLabel>
              <IonInput
                value={this.state.looseData.price}
                onIonChange={(e) => this.setInput(e, "price")}
                type="number"
                disabled={
                  ![UserTypes.admin, UserTypes.manager].includes(
                    this.props.userType
                  )
                }
              />
            </IonItem>
            <IonItem className="loose-form-input">
              <IonLabel position="floating">Order:</IonLabel>
              <IonInput
                value={this.state.looseData.order}
                onIonChange={(e) => this.setInput(e, "order")}
                type="number"
                disabled={
                  ![UserTypes.admin, UserTypes.manager].includes(
                    this.props.userType
                  )
                }
              />
            </IonItem>
            <IonItem className="loose-form-input">
              <IonLabel>VAT: </IonLabel>
              <IonToggle
                checked={!!this.state.looseData.vat}
                onIonChange={this.setVat}
                disabled={
                  ![UserTypes.admin, UserTypes.manager].includes(
                    this.props.userType
                  )
                }
              />
            </IonItem>

            <IonButton
              className="loose-form-save-btn"
              color="primary"
              expand="full"
              onClick={this.submitForm}
            >
              Save
            </IonButton>
          </form>

          {/* Loading */}
          <IonLoading isOpen={this.state.loading} message={"Please wait..."} />
        </IonContent>
      </IonModal>
    );
  }
}
