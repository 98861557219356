import React, { useEffect, useRef, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonRefresher,
  IonRefresherContent,
  IonAlert,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSearchbar,
} from "@ionic/react";
import { addOutline, calendarOutline } from "ionicons/icons";

import "./Stores.scss";

import { StoreItem } from "../../components/Store/StoreItem/StoreItem";
import { StoreAddModal } from "../../components/Store/StoreAddModal/StoreAddModal";
import { IStore } from "../../models/Store";
import { IPaginationMeta } from "../../models/PaginationMeta";
import axios from "axios";
import _ from "lodash";
import { APIs } from "../../services/apiService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export const Stores: React.FC = () => {
  const [userType] = useState(
    useSelector((state: any) => state.auth?.user?.type)
  );
  const [loading, setLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedStoreToDelete, setSelectedStoreToDelete] =
    useState<IStore | null>(null);
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);
  const [search, setSearch] = useState("");
  const [stores, setStores] = useState<IStore[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    current_page: 1,
    per_page: 30,
    last_page: 1,
    total: 0,
  });

  const storeAddModalRef: any = useRef();

  const getStores = async (page: number) => {
    return await axios
      .get(APIs.stores.index, {
        params: {
          per_page: meta.per_page,
          page,
          search_key: search.length > 2 ? search : undefined,
        },
      })
      .then((res) => {
        let { data } = res;
        for (let item of data.data) {
          item.address = JSON.parse(item.address);
        }

        return data;
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in getting store");
        return {
          data: [],
          meta: {},
        };
      });
  };

  const addNewStoreToList = (store: IStore) => {
    // TODO : fix this code
    getInitialStores();
    toast.success("Store has been saved");
  };

  const updateStoreInList = (store: IStore) => {
    // TODO : fix this code
    getInitialStores();
    toast.success("Store has been saved");
  };

  const confirmDeleteStore = (store: IStore) => {
    if (userType === "Sale_Representative") toast.info("Permission Denied");
    else {
      document.querySelector("ion-item-sliding")!.closeOpened();
      setShowDeleteAlert(true);
      setSelectedStoreToDelete(store);
    }
  };

  const deleteStore = () => {
    setLoading(true);

    axios
      .delete(APIs.stores.delete + selectedStoreToDelete!.id)
      .then((res) => {
        doRefresh();
        setSelectedStoreToDelete(null);
        setShowDeleteAlert(false);
        setLoading(false);
        toast.success("store deleted successfully");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in deleting store");
        setLoading(false);
      });
  };

  const editStore = (store: IStore) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    storeAddModalRef.current.showEditModal(store);
  };

  const doFetch = async (event?: any) => {
    let data = await getStores(meta.current_page + 1);

    setStores(stores.concat(data.data!));
    setMeta(data.meta!);

    event.target.complete();

    if (stores.length >= meta.total) setIsInfinityLoadingDisabled(true);
  };

  const doRefresh = async (event?: any) => {
    setLoading(true);
    setStores([]);
    setIsInfinityLoadingDisabled(false);

    let data = await getStores(1);
    setStores(data.data);
    setMeta(data.meta!);
    setLoading(false);

    if (event) event.target.complete();
  };

  const showAddModal = () => {
    storeAddModalRef.current.showModal();
  };

  const getInitialStores = async () => {
    setLoading(true);

    let data = await getStores(1);
    setStores(data.data);
    setMeta(data.meta!);

    setLoading(false);
  };

  /**
   * life cycles
   */
  useEffect(() => {
    getInitialStores();
  }, []);

  useEffect(() => {
    const searchStores = async () => {
      setLoading(true);
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: 1,
      }));

      let data = await getStores(1);

      setStores(data.data);
      setMeta(data.meta!);
      setLoading(false);
    };

    searchStores();
  }, [search]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="danger">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dashboard" />
          </IonButtons>
          <IonTitle>Stores</IonTitle>
          <div className="header-avatar-container" slot="end">
            <IonButtons slot="end" className="menu-container">
              <IonButton>
                <a
                  className="menu-btn"
                  type="text/csv"
                  download="visit-period"
                  href="https://api.roynutfoods.com/api/v1/stores/visit-period"
                >
                  <IonIcon icon={calendarOutline} />
                </a>
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonSearchbar
          value={search}
          onIonChange={(e) => setSearch(e.detail.value!)}
          autocomplete="on"
          debounce={2500}
        />

        <IonList>
          {loading
            ? _.times(10, (i) => <StoreItem key={i} isLoader={true} />)
            : stores.map((store, index) => (
                <IonItemSliding key={index}>
                  <StoreItem
                    routerLink={`/stores/${store.id}/orders`}
                    name={store.name}
                    address={store.address.location}
                  />
                  <IonItemOptions side="start">
                    <IonItemOption
                      color="primary"
                      onClick={(e) => editStore(store)}
                    >
                      Edit
                    </IonItemOption>
                  </IonItemOptions>
                  <IonItemOptions side="end">
                    <IonItemOption
                      color="danger"
                      onClick={() => confirmDeleteStore(store)}
                    >
                      Delete
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              ))}
        </IonList>

        {/* Infinite Scroll */}
        <IonInfiniteScroll
          threshold="10px"
          onIonInfinite={doFetch}
          disabled={isInifinityLoadingDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          />
        </IonInfiniteScroll>

        {/* Fab Button */}
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton color="danger" onClick={showAddModal}>
            <IonIcon icon={addOutline} />
          </IonFabButton>
        </IonFab>

        {/* Add/Edit Modal */}
        <StoreAddModal
          ref={storeAddModalRef}
          onStoreInsert={addNewStoreToList}
          onStoreUpdate={updateStoreInList}
        />

        {/* Delete alert */}
        <IonAlert
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          header={"Delete"}
          message={"Do you want to delete the store?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Delete",
              handler() {
                deleteStore();
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};
