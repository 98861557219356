import { IonLabel } from "@ionic/react";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { IStore } from "../../models/Store";
import { APIs } from "../../services/apiService";
import "./reports.scss";

const Picker = (props: any) => {
  const { item, updateFormData } = props;
  const [value, setValue] = useState(null);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState("");

  const onChangeHandler = (e: any) => {
    setValue(e);
    updateFormData(item.columnName, e?.value);
  };

  const onSearchHandler = (text: string) => {
    if (text !== search) {
      setSearch(text);
    }
  };

  const getStores = (query: string = search) => {
    setItems([]);

    const requestData = {
      params: {
        per_page: 15,
        page: 1,
        from_order: 1,
        search_key: query ? query : undefined,
      },
    };

    axios
      .get(APIs.stores.index, requestData)
      .then((res) => {
        setItems(
          res.data.data.map((it: IStore) => ({ value: it.id, label: it.name }))
        );
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in getting stores");
      });
  };

  useEffect(() => {
    getStores();
  }, [search]);

  return (
    <div className="w-100 h-100 p-2 overflow-hidden">
      <IonLabel
        className="pl-1 font-weight-bold text-success cell-title"
        position="floating"
      >
        {item.title}
      </IonLabel>
      <Select
        options={items}
        value={value}
        placeholder={search}
        isSearchable={true}
        onChange={onChangeHandler}
        onInputChange={_.debounce(onSearchHandler, 2000)}
        onFocus={() => {
          if (items.length === 0) getStores();
        }}
        isClearable
        styles={{
          control: (base) => ({
            ...base,
            borderRadius: 20,
            border: "1px solid #555",
            marginTop: "5px",
            minHeight: 42,
          }),
          menuList: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
      />
    </div>
  );
};

export default React.memo(Picker);
