// @ts-nocheck
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIs } from "../../../services/apiService";
import { OrderForm } from "../../../components/Reports/ProductsPrices/ProductPricesTable";

export const ProductsStocks: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [type, setType] = useState<string>("normal");

  useEffect(() => {
    axios.get(APIs.order.productList).then((res) => {
      let data = res.data.data;
      data = _.chain(data)
        .groupBy("column_number")
        .map((val, key) => ({
          column_number: key,
          categories: val,
        }))
        .value();
      setData(data);
    });
  }, []);

  return (
    <IonPage className="orders-page order-details-page">
      <IonHeader>
        <IonToolbar color="danger">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dashboard" />
          </IonButtons>
          <IonTitle>Product Stocks</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonButton onClick={() => setType("normal")}>Normal Size</IonButton>
        <IonButton onClick={() => setType("other")}>
          Small|Medium|Large Sizes
        </IonButton>
        <OrderForm productList={data} type={type} dataType="stock" />
      </IonContent>
    </IonPage>
  );
};
