import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonListHeader,
  IonLabel,
  IonList,
  IonSelectOption,
  IonSelect,
  IonCheckbox,
} from "@ionic/react";
import { IStore } from "../../../models/Store";

import "./OrderLooseModal.scss";

import axios from "axios";
import _ from "lodash";
import { APIs } from "../../../services/apiService";
import { OrderItem } from "../OrderItem/OrderItem";
import { toast } from "react-toastify";
import { LastOrderItem } from "../LastOrderItem/LastOrderItem";

interface OrderLooseModalProps {
  open: boolean;
  loose: any;
  orderLooses: any;
  storeId: any;
  store?: IStore;
  onDidDismiss: Function;
  changeOrderLoose: Function;
  setOrderLoose: Function;
}

export const OrderLooseModal: React.FC<OrderLooseModalProps> = ({
  open,
  loose,
  onDidDismiss,
  orderLooses,
  storeId,
  changeOrderLoose,
  setOrderLoose,
}) => {
  const [loading, setLoading] = useState(false);
  const [lastOrders, setLastOrders] = useState<any>([]);

  const looseInOrder = _.chain(orderLooses)
    .find((oLoose) => oLoose.id === loose.id)
    .value();
  const onShowModal = () => {
    getLastOrdersOfLoose();
  };

  const onHideModal = () => {
    onDidDismiss();
  };

  const getLastOrdersOfLoose = () => {
    setLoading(true);

    axios
      .get(APIs.looses.lastOrdersOfStore(loose.id, storeId))
      .then((res) => {
        setLastOrders(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error getting last orders");
      });
  };

  return (
    <IonModal
      cssClass="order-loose-modal"
      isOpen={open}
      onWillPresent={onShowModal}
      onDidDismiss={onHideModal}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{loose.title}:</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onHideModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="change-product-size-count-section">
            <IonCol size="5"></IonCol>
            <IonCol size="7">
              <IonRow>
                <IonCol size="3">
                  <IonLabel>VAT: </IonLabel>
                </IonCol>
                <IonCol size="9">
                  <IonCheckbox
                    title="vat"
                    checked={loose?.vat == 1 ? true : false}
                    disabled
                  ></IonCheckbox>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size="12"
              className="change-product-size-count-section d-flex justify-content-center"
            >
              <IonButton
                size="small"
                color="danger"
                onClick={() => changeOrderLoose(loose, "remove")}
              >
                -
              </IonButton>
              <IonSelect
                value={looseInOrder?.quantity ?? 0}
                placeholder=""
                interface="popover"
                onIonChange={(e) => setOrderLoose(loose, e.detail.value)}
              >
                {_.times(100, (i) => (
                  <IonSelectOption key={i} value={i}>
                    {i}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <IonButton
                size="small"
                color="success"
                onClick={() => changeOrderLoose(loose, "add")}
              >
                +
              </IonButton>
              <div className="product-price">£{loose?.price_with_vat}</div>
            </IonCol>
            <IonCol size="12">
              <IonList>
                <IonListHeader>
                  <IonLabel className="last-orders__title">
                    Last orders:
                  </IonLabel>
                </IonListHeader>
                {loading
                  ? _.times(5, (i) => <OrderItem key={i} isLoader={true} />)
                  : _.map(lastOrders, (lastOrder, index) => (
                      <LastOrderItem
                        key={index}
                        count={lastOrder.quantity}
                        date={lastOrder.created_at}
                        status={lastOrder.status}
                      />
                    ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* Loading */}
        {/*<IonLoading*/}
        {/*  isOpen={loading}*/}
        {/*  message={'Please wait...'}*/}
        {/*/>*/}
      </IonContent>
    </IonModal>
  );
};
