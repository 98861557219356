import React from 'react';
import {IonAvatar} from '@ionic/react';

import './Avatar.scss';

interface AvatarProps {
  img?: string;
  alt?: string;
  label?: string;
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({img, alt = 'img', label = 'A', className}) => {
  const text = label.substr(0, 1).toUpperCase();

  return (
    <>
      <IonAvatar className={`avatar ${className}`}>
        {img
          ?
          <img className="avatar-img" src={img} alt={alt}/>
          :
          <span className="no-avatar">{text}</span>
        }
      </IonAvatar>
    </>
  )
}
