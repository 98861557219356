import { IonCol, IonRow } from "@ionic/react";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import { IOrder, ORDER_STATUS } from "../../../models/Order";
import "./LastOrderItemFull.scss";

interface LastOrderItemFullProps {
  order: IOrder;
  productId?: string;
}

/**
 * this component will render total summary of product (sizes)
 *
 * @param order
 * @constructor
 */
export const LastOrderItemFull: React.FC<LastOrderItemFullProps> = ({
  order,
}) => {
  if (order)
    return (
      <IonRow className="order-summary-full" key={order.id}>
        <IonCol>
          <div
            className={classnames({
              "pending-order-status": order.status == ORDER_STATUS.pending,
              "complete-order-status": order.status == ORDER_STATUS.complete,
              "order-status": true,
            })}
          ></div>
        </IonCol>
        <IonCol className="date">
          {order.created_at
            ? moment(order.created_at).format("DD/MM/YYYY")
            : ""}
        </IonCol>
        <IonCol>
          <span>S</span> <br /> {order.count.small}
        </IonCol>
        <IonCol>
          <span>N</span> <br /> {order.count.normal}
        </IonCol>
        <IonCol>
          <span>M</span> <br /> {order.count.medium}
        </IonCol>
        <IonCol>
          <span>L</span> <br /> {order.count.large}
        </IonCol>
        <IonCol>
          <span>H</span> <br /> {order.count.half}
        </IonCol>
      </IonRow>
    );
  else return null;
};
