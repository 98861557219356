export interface IUser {
  id: string;
  name: string;
  type: string;
  email: string;
  username: string;
  password: string;
  avatar: string;
  created_at: string;
  updated_at: string;
}

export const UserTypes = {
  admin: "Admin",
  manager: "Manager",
  saleRepresentative: "Sale_Representative",
  stockController: "Stock_Controller",
  picker: "Picker",
};
