import axios from "axios";
import { history } from "../AppRouter";
import _ from "lodash";

export const registerAxiosErrorHandlerToAxios = () => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        history.replace("/login");
      } else if (error.response.status === 422) {
        try {
          error.customErrorMessage = _.values(error.response.data.errors)[0][0];
        } catch (e) {
          error.customErrorMessage = "please try again and check all inputs";
        }
      }

      return Promise.reject(error);
    }
  );
};
