export const dailyProductColumns = [
  {
    columnName: "product_size_id",
    label: "Code",
    type: "number",
    component: "input",
  },
  {
    columnName: "batch_number",
    label: "Batch number",
    type: "number",
    component: "input",
  },
  {
    columnName: "quantity",
    label: "Warehouse stock",
    type: "number",
    component: "input",
  },
  {
    columnName: "weight",
    label: "Weight of each box",
    type: "number",
    component: "input",
  },
  {
    columnName: "production_date",
    label: "Production date",
    type: "date",
    component: "date",
  },
  {
    columnName: "expiration_date",
    label: "Expiration date",
    type: "date",
    component: "date",
  },
];
