import React from "react";
import "./ReturnPanel.scss";
import _ from "lodash";
import { IonButton, IonInput, IonSelect, IonSelectOption } from "@ionic/react";

export const ReturnPanel: React.FC<any> = ({
  order,
  setOrderReturnCode,
  setOrderReturnCount,
  setOrderReturnType,
  deleteItemFromReturns,
  addItemToReturns,
}) => {
  return (
    <div className="return-table w-100">
      {order.returns?.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Return Code</th>
              <th>Count</th>
              <th>Type</th>
              <th>X</th>
            </tr>
          </thead>
          <tbody>
            {_.map(order.returns, (returnItem, index) => (
              <tr>
                <td className="p-0" width="50%">
                  <IonInput
                    // tabIndex={-1}
                    value={returnItem.code}
                    onIonChange={(e) => setOrderReturnCode(e, index)}
                  ></IonInput>
                </td>
                <td className="p-0" width="35%">
                  <IonInput
                    // tabIndex={-1}
                    value={returnItem.count}
                    onIonChange={(e) => setOrderReturnCount(e, index)}
                  ></IonInput>
                </td>
                <td className="p-0" width="20%">
                  <IonSelect
                    placeholder="Select Store"
                    okText="Select"
                    cancelText="Cancel"
                    value={returnItem.type}
                    onIonChange={(e) => setOrderReturnType(e, index)}
                  >
                    <IonSelectOption value="bag">Bag</IonSelectOption>
                    <IonSelectOption value="box">Box</IonSelectOption>
                  </IonSelect>
                </td>
                <td className="p-0" width="15%">
                  <IonButton
                    className="w-100 mt-3"
                    tabIndex={-1}
                    color="danger"
                    onClick={() => deleteItemFromReturns(index)}
                  >
                    X
                  </IonButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className="w-100 return-button">
        <IonButton
          tabIndex={-1}
          color="success"
          expand="full"
          onClick={addItemToReturns}
        >
          Add RETURN
        </IonButton>
      </div>
    </div>
  );
};
