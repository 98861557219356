import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { APIs } from "../../../services/apiService";
import "./LoadUnLoad.scss";
import { LoadModal } from "./LoadModal/Load";

export const LoadUnLoadModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(props.displayModal);
  const [displayLoadModal, setDisplayLoadModal] = useState(false);
  const [searchedLocation, setSearchedLocation] = useState("");
  const [locations, setLocations] = useState<any[]>(props.locations ?? []);
  const [filteredLocations, setFilteredLocations] = useState<any[]>([]);
  const [selected, setSelected] = useState(false);
  const [isFree, setFree] = useState<any>(false);
  const [meta, setMeta] = useState<any>({
    current_page: 1,
    per_page: 30,
    last_page: 1,
    total: 0,
  });
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);

  const toggleModal = () => {
    props.toggleModal();
  };

  const columns = [
    { columnsName: "aisle", label: "Aisle", type: "string" },
    { columnsName: "bay", label: "Bay", type: "string" },
    { columnsName: "level", label: "Level", type: "string" },
    { columnsName: "position", label: "Position", type: "string" },
    { columnsName: "active", label: "Active", type: "string" },
    { columnsName: "title", label: "Product Title", type: "obj" },
    { columnsName: "code", label: "Product Code", type: "obj" },
    { columnsName: "kind", label: "Type", type: "obj" },
    // { columnsName: "action", label: "Action", type: "action" },
  ];

  const renderValue = (row: any, col: any) => {
    if (col.type === "string") return row[col.columnsName];
    else if (row["sizes"]?.length > 0) {
      if (col.columnsName === "title") return row["sizes"][0].title;
      else if (col.columnsName === "code") return row["sizes"][0].code;
      else if (col.columnsName === "kind") return row["sizes"][0].kind;
    }
    return "-";
  };

  const clickHandler = (data: any, type: string) => {
    if (type === "load") {
      setSelected(data);
      setDisplayLoadModal(true);
    } else if (type === "unload" && data.sizes?.length > 0) {
      setLoading(true);
      axios
        .get(APIs["product-size"].searchByCode(data.sizes[0].code))
        .then((res) => {
          if (res.data.data?.length > 0)
            axios
              .delete(
                APIs["product-size"].unLoad(res.data.data[0].id, data.id),
                data
              )
              .then((res) => {
                setLoading(false);

                getLocations(0);
                // props.getInitialLocations();
                toast.success("loading has been successful!");
              })
              .catch((e) => setLoading(false));
        })
        .catch((e) => {
          setLoading(false);
          toast.success("something went wrong!");
        });
    }
  };

  const onLoadedData = () => {
    getLocations(0);
    toast.success("unloading has been successful!");
  };

  const getLocations = async (
    page: number,
    free?: number,
    isInfiniteScroll?: boolean
  ) => {
    if (!isInfiniteScroll) setLoading(true);
    return await axios
      .get(APIs.locations.search, {
        params: {
          per_page: meta.per_page,
          page,
          search_key:
            searchedLocation.length > 0 ? searchedLocation : undefined,
          is_free: free ?? (isFree ? 1 : 0),
        },
      })
      .then((res) => {
        if (!isInfiniteScroll) setLoading(false);
        if (searchedLocation?.length > 0) {
          setFilteredLocations((locations: any[]) => [
            ...locations,
            ...res.data.data,
          ]);
        } else {
          setLocations((locations: any[]) => [...locations, ...res.data.data]);
        }
        setMeta(res.data.meta!);
        if (res.data.meta?.to >= meta.total) setIsInfinityLoadingDisabled(true);
      })
      .catch((err) => {
        if (!isInfiniteScroll) setLoading(false);

        toast.error(
          err.customErrorMessage ?? "error in getting locations list"
        );
      });
  };

  useEffect(() => {
    if (searchedLocation?.length > 0) {
      setLoading(true);
      setMeta((prevMeta: any) => ({
        ...prevMeta,
        current_page: 1,
      }));
      getLocations(meta.current_page);
    } else {
      setFilteredLocations([]);
    }
  }, [searchedLocation]);

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
    >
      {displayLoadModal && (
        <LoadModal
          displayModal={displayLoadModal}
          toggleModal={() => setDisplayLoadModal(!displayLoadModal)}
          selected={selected}
          onLoadedData={onLoadedData}
        />
      )}

      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Load and UnLoad:</IonTitle>
          <IonButtons slot="end">
            <IonButton
              className="text-success font-weight-bold"
              onClick={() => {}}
            >
              Save
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={toggleModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonRefresher slot="fixed" onIonRefresh={props.getInitialLocations}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonSearchbar
          className="px-0 mb-2"
          value={searchedLocation}
          onIonChange={(e) => setSearchedLocation(e.detail.value!)}
          debounce={2500}
          placeholder="Code or A'name':B'name' e.g. aisle named Roy => ARoy"
        />

        <IonItem className="p-0 m-0">
          <IonLabel>Show Only Free Spaces: </IonLabel>
          <IonToggle
            checked={isFree}
            onIonChange={(e) => {
              setFree((e: any) => (isFree ? false : true));
              getLocations(0, isFree ? 0 : 1);
            }}
          />
        </IonItem>
        <div style={{ overflowX: "auto" }}>
          <table className="product-update-table">
            <thead>
              <tr className="product-update-form-size-title">
                <th>
                  <h2>Aisle</h2>
                </th>
                <th>
                  <h2>Bay</h2>
                </th>
                <th>
                  <h2>Level</h2>
                </th>
                <th>
                  <h2>Position</h2>
                </th>
                <th>
                  <h2>Active</h2>
                </th>
                <th>
                  <h2>Product Title</h2>
                </th>
                <th>
                  <h2>Product Code</h2>
                </th>
                <th>
                  <h2>Type</h2>
                </th>
                <th>
                  <h2>Action</h2>
                </th>
              </tr>
            </thead>
            <tbody>
              {(filteredLocations?.length > 0
                ? filteredLocations
                : locations
              )?.map((loc: any) => {
                return (
                  <tr key={loc.title}>
                    {columns.map((col) => {
                      return (
                        <td className="font-weight-bold">
                          {renderValue(loc, col)}
                        </td>
                      );
                    })}
                    <td
                      className="font-weight-bold action"
                      onClick={() =>
                        clickHandler(
                          loc,
                          loc.sizes?.length > 0 ? "unload" : "load"
                        )
                      }
                    >
                      {loc.sizes?.length > 0 ? "unload" : "load"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Loading */}
        <IonLoading isOpen={loading} message={"Please wait..."} />

        <IonInfiniteScroll
          threshold="10px"
          onIonInfinite={() =>
            getLocations(meta.current_page + 1, isFree ? 1 : 0, true)
          }
          disabled={isInifinityLoadingDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          />
        </IonInfiniteScroll>
      </IonContent>
    </IonModal>
  );
};
