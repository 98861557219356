import React from "react";
import "./OrderLooseItem.scss";
import _ from "lodash";
import { useLongPress } from "use-long-press";

export const OrderLooseItem: React.FC<any> = ({
  loose,
  order,
  changeOrderLoose,
  disabled = false,
  showLooseModal,
}) => {
  const looseSizeIndex = _.findIndex(order.looses, { id: loose.id });
  let lastLongClick = 0;

  const onSizeClickHandler = () => {
    if (Date.now() - lastLongClick > 250) {
      // prevent click get triggered after long press
      if (!disabled && changeOrderLoose) {
        changeOrderLoose(loose, "add");
      }
    }
  };

  const onLongPress = () => {
    if (changeOrderLoose) {
      changeOrderLoose(loose, "remove");
    }
  };

  // @ts-ignore
  const longPressEvent = useLongPress(onLongPress, {
    cancelOnMovement: true,
    threshold: 250,
    detect: "both",
    onFinish: (event) => {
      lastLongClick = Date.now();
    },
  });

  return (
    <tr>
      <td width="60%" className="loose-name">
        <div onClick={() => showLooseModal(loose)}>{loose.title}</div>
      </td>
      <td width="20%" {...longPressEvent}>
        <div
          className="w-100 h-100 loose-quantity"
          onClick={onSizeClickHandler}
        >
          {
            // if this is from log form, it needs pivot to check quantity
            looseSizeIndex > -1
              ? !order.looses[looseSizeIndex].quantity
                ? order.looses[looseSizeIndex].pivot.quantity
                : order.looses[looseSizeIndex].quantity
              : ""
          }
        </div>
      </td>
      <td className="p-0" width="20%">
        £{loose.price_with_vat}
      </td>
    </tr>
  );
};
