import React from 'react';
import {
  IonButton,
  IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonLoading,
  IonModal, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToggle, IonToolbar
} from '@ionic/react';
import './TicketAddModal.scss';
import {ITicket} from '../../../models/Ticket';
import {IUser} from '../../../models/User';
import axios from 'axios';
import {APIs} from '../../../services/apiService';
import {toast} from 'react-toastify';

interface TicketAddModalStates {
  isModalShowing: boolean;
  loading: boolean;
  mode: 'add' | 'edit';
  sendToAll: boolean | number;
  ticketData: ITicket;
  users: Array<IUser>;
}

interface TicketAddModalProps {
  onTicketInsert: Function;
  onTicketUpdate: Function;
}


export class TicketAddModal extends React.Component<TicketAddModalProps, TicketAddModalStates> {

  constructor(props: any) {
    super(props);

    this.state = {
      isModalShowing: false,
      loading: false,
      mode: 'add',
      sendToAll: false,
      users: [],
      ticketData: {
        id: '',
        title: '',
        message: '',
        status: ''
      }
    };

    this.getUsers();
  }

  showModal = () => {
    this.setState({isModalShowing: true});
  }

  showEditModal = (ticket: ITicket) => {
    this.setState({
      ticketData: {
        ...ticket
      },
      isModalShowing: true,
      mode: 'edit'
    })
  }

  hideModal = () => {
    this.setState({isModalShowing: false});
  }

  clearModal = () => {
    this.setState({
      loading: false,
      mode: 'add',
      ticketData: {
        id: '',
        title: '',
        message: '',
        status: ''
      }
    });
  }

  setInput = (e: any, name: string) => {
    this.setState(state => ({
      ticketData: {
        ...state.ticketData,
        [name]: e.target.value
      }
    }));
  }

  setUser = (e: any) => {
    const userId = e.target.value;

    this.setState(prevState => ({
      ticketData: {
        ...prevState.ticketData,
        to: userId
      }
    }));
  }

  setSendToAll = (e: any) => {
    this.setState({sendToAll: e.detail.checked ? 1 : 0});
  }

  submitForm = () => {
    if (this.state.mode === 'add') {
      this.saveTicket();
    } else {
      this.editTicket();
    }
  }

  saveTicket = () => {
    this.setState({
      loading: true
    });

    const ticketStoreRequestData = new FormData();

    ticketStoreRequestData.append('title', this.state.ticketData.title);
    ticketStoreRequestData.append('message', this.state.ticketData.message);

    if (this.state.sendToAll)
      ticketStoreRequestData.append('to', 'all');
    else
      ticketStoreRequestData.append('to[]', this.state.ticketData.to);

    axios.post(APIs.tickets.store, ticketStoreRequestData)
      .then(res => {
        this.props.onTicketInsert!(res.data.data);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch(err => {
        toast.error(err.customErrorMessage ?? 'error in saving ticket');
        this.setState({
          loading: false
        });
      });
  }

  editTicket = () => {
    this.setState({
      loading: true
    });

    const ticketEditRequestData = new FormData();

    ticketEditRequestData.append('title', this.state.ticketData.title);
    ticketEditRequestData.append('message', this.state.ticketData.message);
    ticketEditRequestData.append('_method', 'put');

    axios.post(APIs.tickets.update(this.state.ticketData.id), ticketEditRequestData)
      .then(() => {
        this.props.onTicketUpdate!(this.state.ticketData);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch(err => {
        toast.error(err.customErrorMessage ?? 'error in updating ticket');
        this.setState({
          loading: false
        });
      });
  }

  getUsers = () => {
    axios.get(APIs.users.indexAll)
      .then(res => {
        this.setState({
          users: res.data
        });
      })
      .catch(err => {
        toast.error(err.customErrorMessage ?? 'error getting users list');
      });
  }

  render() {
    return (
      <IonModal
        isOpen={this.state.isModalShowing}
        onDidDismiss={this.clearModal}
        swipeToClose={false}
        keyboardClose={false}
        backdropDismiss={false}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>New Ticket:</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={this.hideModal}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          <form>
            <IonItem className="ticket-form-input">
              <IonLabel position="floating">Title:</IonLabel>
              <IonInput
                value={this.state.ticketData.title}
                onIonChange={(e) => this.setInput(e, 'title')}
              />
            </IonItem>
            <IonItem className="ticket-form-input">
              <IonLabel position="floating">Message:</IonLabel>
              <IonTextarea
                className="ticket-message"
                value={this.state.ticketData.message}
                onIonChange={(e) => this.setInput(e, 'message')}
              ></IonTextarea>
            </IonItem>
            {
              (!this.state.sendToAll && this.state.mode === 'add') &&
              <IonItem className="ticket-form-input">
                <IonLabel position="floating">User:</IonLabel>
                <IonSelect
                  value={this.state.ticketData.to}
                  onIonChange={this.setUser}
                  interface="alert"
                >
                  {
                    this.state.users.map(user =>
                      <IonSelectOption key={user.id} value={user.id}>{user.name}</IonSelectOption>
                    )
                  }
                </IonSelect>
              </IonItem>
            }
            {
              this.state.mode === 'add' &&
              <IonItem className="ticket-form-input">
                <IonLabel>Send to all: </IonLabel>
                <IonToggle
                  checked={!!this.state.sendToAll}
                  onIonChange={this.setSendToAll}
                />
              </IonItem>
            }
            <IonButton
              className="ticket-form-save-btn"
              color="primary"
              expand="full"
              onClick={this.submitForm}
            >Save</IonButton>
          </form>

          {/* Loading */}
          <IonLoading
            isOpen={this.state.loading}
            message={'Please wait...'}
          />
        </IonContent>
      </IonModal>
    );
  }
}
