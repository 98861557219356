import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";

import React from "react";
import { useLocation } from "react-router-dom";
import {
  cartOutline,
  fastFoodOutline,
  fileTrayStackedOutline,
  peopleOutline,
  personOutline,
  powerOutline,
  speedometerOutline,
  storefrontOutline,
  ticketOutline,
  barChartOutline,
} from "ionicons/icons";
import "./Menu.css";
import { Avatar } from "../Avatar/Avatar";
import { setAuthToken } from "../../services/authService";
import { history } from "../../AppRouter";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../store/actionTypes";
import { UserTypes } from "../../models/User";
import SageIcon from "../../assets/img/sage.svg";
import axios from "axios";
import { APIs } from "../../services/apiService";
import { toast } from "react-toastify";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  accessFor: Array<string>;
}

const appPages: AppPage[] = [
  {
    title: "Dashboard",
    url: "/dashboard",
    iosIcon: speedometerOutline,
    mdIcon: speedometerOutline,
    accessFor: [
      UserTypes.admin,
      UserTypes.manager,
      UserTypes.picker,
      UserTypes.saleRepresentative,
      UserTypes.stockController,
    ],
  },
  {
    title: "Reports",
    url: "/reports",
    iosIcon: barChartOutline,
    mdIcon: barChartOutline,
    accessFor: [UserTypes.admin, UserTypes.manager],
  },
  {
    title: "Users",
    url: "/users",
    iosIcon: peopleOutline,
    mdIcon: peopleOutline,
    accessFor: [UserTypes.admin, UserTypes.manager],
  },
  {
    title: "Categories",
    url: "/categories",
    iosIcon: fileTrayStackedOutline,
    mdIcon: fileTrayStackedOutline,
    accessFor: [UserTypes.admin, UserTypes.manager],
  },
  {
    title: "Products",
    url: "/products",
    iosIcon: cartOutline,
    mdIcon: cartOutline,
    accessFor: [UserTypes.admin, UserTypes.manager, UserTypes.stockController],
  },
  {
    title: "Looses",
    url: "/looses",
    iosIcon: fastFoodOutline,
    mdIcon: fastFoodOutline,
    accessFor: [UserTypes.admin, UserTypes.manager, UserTypes.stockController],
  },
  {
    title: "Stores",
    url: "/stores",
    iosIcon: storefrontOutline,
    mdIcon: storefrontOutline,
    accessFor: [
      UserTypes.admin,
      UserTypes.manager,
      UserTypes.saleRepresentative,
    ],
  },
  {
    title: "Tickets",
    url: "/tickets",
    iosIcon: ticketOutline,
    mdIcon: ticketOutline,
    accessFor: [
      UserTypes.admin,
      UserTypes.manager,
      UserTypes.picker,
      UserTypes.saleRepresentative,
      UserTypes.stockController,
    ],
  },
  {
    title: "Warehouse",
    url: "/warehouse",
    iosIcon: ticketOutline,
    mdIcon: ticketOutline,
    accessFor: [
      UserTypes.admin,
      UserTypes.manager,
      UserTypes.picker,
      UserTypes.saleRepresentative,
      UserTypes.stockController,
    ],
  },
];

const Menu: React.FC = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const userName: any = useSelector<any>((state) => state.auth.user.name);
  const userType: any = useSelector<any>((state) => state.auth.user.type);
  const userAvatar: any = useSelector<any>((state) => state.auth?.user?.avatar);

  const logOutUser = () => {
    setAuthToken(null);
    dispatch({
      type: Actions.AUTH.USER_LOGOUT,
    });
    history.replace("/login");
  };

  const sageSignIn = () => {
    axios
      .get(APIs.users.sageSignIn)
      .then((res) => {
        window.open(res.data?.sageUrl, "_self");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in sage login");
      });
  };

  const backupDB = () => {
    axios
      .get(APIs.backup.download)
      .then((res) => {
        toast.success(res?.data ?? "Backup Saved");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in DB Backup");
      });
  };

  return (
    <IonMenu swipeGesture={false} menuId="main" contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader className="nav-user-avatar">
            <Avatar label={userName} img={userAvatar} />
          </IonListHeader>
          <div className="nav-user-info-container">
            <IonNote>{userName}</IonNote>
            <IonNote className="nav-user-type">({userType})</IonNote>
          </div>
          {appPages.map((appPage, index) => {
            if (!appPage.accessFor.includes(userType)) {
              return;
            }

            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
        <IonList id="labels-list">
          <IonMenuToggle autoHide={false}>
            <IonItem
              className={location.pathname === "/profile" ? "selected" : ""}
              routerLink="/profile"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" icon={personOutline} />
              <IonLabel>Profile</IonLabel>
            </IonItem>
            {["Admin" || "Manager"].includes(userType) ? (
              <IonItem
                lines="none"
                detail={false}
                onClick={sageSignIn}
                class="menu-button"
              >
                <IonIcon slot="start" icon={SageIcon} />
                <IonLabel>Sage</IonLabel>
              </IonItem>
            ) : null}
            {/* {["Admin" || "Manager"].includes(userType) ? (
              <IonItem
                lines="none"
                detail={false}
                onClick={backupDB}
                class="menu-button"
              >
                <IonIcon slot="start" icon={layersOutline} />
                <IonLabel>Database Backup</IonLabel>
              </IonItem>
            ) : null} */}
            <IonItem
              lines="none"
              detail={false}
              onClick={logOutUser}
              class="menu-button"
            >
              <IonIcon slot="start" icon={powerOutline} />
              <IonLabel>Logout</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
