// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "./reports.scss";

export const Table = (props: any) => {
  let { name } = useParams();
  const productTable = [
    { columnName: "product_title", label: "Product" },
    { columnName: "code", label: "Code" },
    { columnName: "total_count", label: "Sell" },
  ];
  const returnTable = [
    { columnName: "store_name", label: "Store Name" },
    { columnName: "user_name", label: "Seller" },
    { columnName: "date", label: "ِDate" },
    { columnName: "code", label: "Code" },
    { columnName: "count", label: "Count" },
  ];
  const productsPricesTable = [
    { columnName: "title", label: "Store Name" },
    { columnName: "price", label: "Price" },
    { columnName: "stock", label: "Stock" },
  ];
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) setData(props.data);
  }, [props]);

  const getTable = () => {
    if (name === "stores") return productTable;
    else if (name === "returns") return returnTable;
    else if (name === "productsPrices") return productsPricesTable;
  };

  return (
    <table className="report-table">
      <thead>
        <tr>
          {getTable().map((head: any) => {
            return (
              <th className="report-form-size-title" key={head.label}>
                {head.label}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data?.map((d, index) => {
          let tbl = getTable();
          return (
            <tr key={"row" + index}>
              {tbl.map((td, i) => (
                <td key={`${data}${index}${i}`}>{d[td["columnName"]] ?? ""}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
