// @ts-nocheck
import React from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

import _ from "lodash";
import { ProductRow } from "./ProductPricesTableRow";

export const OrderForm = ({ productList, type, dataType }) => {
  return (
    <IonGrid className="p1-0 w-100">
      <div className="order-form-container w-100">
        <IonRow className="order-form order-form-logged">
          {_.map(productList, (col) => (
            <IonCol
              key={col.column_number}
              className={`col-${col.column_number}`}
              sizeXl="4"
              sizeMd="4"
              size="4"
            >
              {_.map(col.categories, (category) => (
                <table className="order-form-table" key={category.id}>
                  <thead>
                    <tr className="order-form-category">
                      <th className="order-form-category-name">
                        <h2>{category.title}</h2>
                      </th>
                      {type === "normal" ? (
                        <th className="size">
                          <h2>N</h2>
                        </th>
                      ) : (
                        <>
                          <th className="size">
                            <h2>S</h2>
                          </th>
                          <th className="size">
                            <h2>M</h2>
                          </th>
                          <th className="size">
                            <h2>L</h2>
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {_.map(category.products, (product) => (
                      <ProductRow
                        key={product.id}
                        product={product}
                        dataType={dataType}
                        type={type}
                      />
                    ))}
                  </tbody>
                </table>
              ))}
            </IonCol>
          ))}
        </IonRow>
      </div>
    </IonGrid>
  );
};
