import React from 'react';
import {IonItem, IonLabel, IonSkeletonText, IonText} from '@ionic/react';
import {Avatar} from '../../Avatar/Avatar';
import './TicketItem.scss';

interface TicketItemProps {
  id?: string;
  title?: string;
  message?: string;
  isLoader?: boolean;
  to?: any;
  seen?: boolean;
}


export const TicketItem: React.FC<TicketItemProps> = ({id, title, seen, isLoader, message, to}) => {
  if (isLoader) {
    return (
      <IonItem className="ticket-item">
        <IonSkeletonText animated style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
        <IonLabel className="ticket-item-info">
          <IonSkeletonText animated style={{width: '40%', height: '16px', marginBottom: '5px'}}/>
          <IonSkeletonText animated style={{width: '100%', height: '12px'}}/>
        </IonLabel>
      </IonItem>
    )
  }
  return (
    <IonItem routerLink={`/ticket/${id}`} className="ticket-item">
      <Avatar label="T"/>
      <IonLabel className="ticket-item-info">
        <h3>
          {title}
          {
            !seen &&
            <IonText color="danger"> (New)</IonText>
          }
        </h3>
        <p>{to.name}</p>
      </IonLabel>
    </IonItem>
  );
}
