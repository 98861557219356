import axios from 'axios';
import {APIs} from './apiService';
import {IUser} from '../models/User';

export const setAuthToken = (token?: string | null): void => {
  if (token) {
    localStorage.setItem('token', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const checkAuth = async (): Promise<IUser | null> => {
  if (!localStorage.getItem('token'))
    return null;

  const token = localStorage.getItem('token');
  setAuthToken(token);

  try {
    return await axios.get(APIs.profile.get).then(res => res.data.data);
  } catch (e) {
    return null
  }

}
