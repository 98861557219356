import { IOrder } from "../../../models/Order";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { PRODUCT_SIZES } from "../../../models/ProductSize";
import { IonCol, IonRow } from "@ionic/react";
import "./OrderSummary.scss";
import { useSelector } from "react-redux";
import { UserTypes } from "../../../models/User";

interface OrderSummaryProps {
  order: IOrder;
  summaryType: "new" | "last";
  isDraft?: boolean;
  discount?: string | number;
}

/**
 * this component will render total summary of product (sizes and total balance)
 *
 * @param order
 * @param summaryType
 * @param isDraft
 * @param discount
 * @constructor
 */
export const OrderSummary: React.FC<OrderSummaryProps> = ({
  order,
  summaryType,
  isDraft,
  discount,
}) => {
  const userType: any = useSelector<any>((state) => state.auth?.user?.type);
  const [total, setTotal] = useState({
    small: 0,
    normal: 0,
    medium: 0,
    large: 0,
    half: 0,
    loose: 0,
    total: 0,
  });
  const [totalPrice, setTotalPrice] = useState<string | number>(0);
  useEffect(() => {
    // calculate total boxes count
    const groupedSizes = _.chain(order.sizes)
      .groupBy("type")
      .map((orderSizes, type) => ({
        type: type,
        count: _.chain(orderSizes)
          .map(summaryType === "new" || isDraft ? "count" : "pivot.count")
          .reduce((sum, c) => sum + c)
          .value(),
      }))
      .value();

    const smallSizeIndex = _.findIndex(groupedSizes, {
      type: PRODUCT_SIZES.small,
    });
    const normalSizeIndex = _.findIndex(groupedSizes, {
      type: PRODUCT_SIZES.normal,
    });
    const mediumSizeIndex = _.findIndex(groupedSizes, {
      type: PRODUCT_SIZES.medium,
    });
    const largeSizeIndex = _.findIndex(groupedSizes, {
      type: PRODUCT_SIZES.large,
    });
    const totalHalfs = order.halves.length;
    const totalLoose = _.chain(order.looses)
      .map(summaryType === "new" || isDraft ? "quantity" : "pivot.quantity")
      .sum()
      .value();

    let total =
      _.chain(groupedSizes)
        .map("count")
        .reduce((sum, c) => sum + c)
        .value() || 0;

    total += totalHalfs; //loose are not counted

    setTotal({
      small: smallSizeIndex > -1 ? groupedSizes[smallSizeIndex].count : 0,
      normal: normalSizeIndex > -1 ? groupedSizes[normalSizeIndex].count : 0,
      medium: mediumSizeIndex > -1 ? groupedSizes[mediumSizeIndex].count : 0,
      large: largeSizeIndex > -1 ? groupedSizes[largeSizeIndex].count : 0,
      half: totalHalfs,
      loose: totalLoose,
      total: total,
    });

    // calculate total price from order.sizes + order.halves
    const sizesTotalPrice =
      _.chain(order.sizes)
        .mapValues((size) => {
          let val = size.count * Number(size.price);
          val = size.vat === 1 ? val * 1.2 : val;
          // size?.vat == 1 ? Number(size.price) * 1.2 : Number(size.price);
          return val;
        })
        .reduce((sum, p) => sum + p)
        .value() ?? 0;

    const halvesTotalPrice =
      _.chain(order.halves_data)
        .mapValues((size) => {
          let val = Number(size.price) * 0.5;
          val = size.vat === 1 ? val * 1.2 : val;
          return val;
        })
        .reduce((sum, p) => sum + p)
        .value() ?? 0;

    const looseTotalPrice =
      _.chain(order.looses)
        .map(
          (loose) =>
            // @ts-ignore
            loose.quantity *
            (loose.vat === 1 ? Number(loose.price) * 1.2 : Number(loose.price))
        )
        .sum()
        .value() ?? 0;

    // total count of boxes
    setTotalPrice(
      (
        ((Number(sizesTotalPrice) ?? 0) +
          (Number(halvesTotalPrice) ?? 0) +
          (Number(looseTotalPrice) ?? 0)) *
        (Number(discount) > 0 ? 1 - Number(discount) / 100 : 1)
      ).toFixed(2)
    );
  }, [order]);

  return (
    <IonRow className="order-summary">
      <IonCol sizeSm="2" size="12">
        Total:{" "}
        {total.loose > 0 ? `${total.total} + ${total.loose}L` : total.total}
      </IonCol>
      <IonCol sizeSm="1" size="2">
        S: {total.small}
      </IonCol>
      <IonCol sizeSm="1" size="2">
        N: {total.normal}
      </IonCol>
      <IonCol sizeSm="1" size="2">
        M: {total.medium}
      </IonCol>
      <IonCol sizeSm="1" size="2">
        L: {total.large}
      </IonCol>
      <IonCol sizeSm="1" size="2">
        H: {total.half}
      </IonCol>
      <IonCol sizeSm="1" size="2">
        Loose: {total.loose}
      </IonCol>
      <IonCol sizeSm="4" size="12">
        Total Balance:
        {[UserTypes.admin, UserTypes.manager].includes(userType)
          ? summaryType === "new" || isDraft
            ? totalPrice
            : order.payable_price
            ? ` £${order.payable_price}`
            : " 0"
          : " -"}
      </IonCol>
    </IonRow>
  );
};
