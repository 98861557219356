import React from 'react';
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea, IonLoading
} from '@ionic/react';

import './CategoryAddModal.scss'
import {ICategory} from '../../../models/Category';
import axios from 'axios';
import {APIs} from '../../../services/apiService';
import {toast} from 'react-toastify';


interface CategoryAddModalStates {
  isModalShowing: boolean;
  loading: boolean;
  mode: 'add' | 'edit';
  categoryData: ICategory;
}

interface CategoryAddModalProps {
  onCategoryInsert: Function;
  onCategoryUpdate: Function;
}

export class CategoryAddModal extends React.Component<CategoryAddModalProps, CategoryAddModalStates> {

  constructor(props: any) {
    super(props);

    this.state = {
      isModalShowing: false,
      loading: false,
      mode: 'add',
      categoryData: {
        id: '',
        title: '',
        description: '',
        order: 0,
        column_number: 0,
      }
    };
  }

  showModal = () => {
    this.setState({isModalShowing: true});
  }

  showEditModal = (category: ICategory) => {
    this.setState({
      categoryData: {
        id: category.id,
        title: category.title,
        description: category.description,
        order: category.order,
        column_number: category.column_number,
      },
      isModalShowing: true,
      mode: 'edit'
    })
  }

  hideModal = () => {
    this.setState({isModalShowing: false});
  }

  clearModal = () => {
    this.setState({
      loading: false,
      mode: 'add',
      categoryData: {
        id: '',
        title: '',
        description: '',
        order: 0,
        column_number: 0,
      }
    });
  }

  setInput = (e: any, name: string) => {
    this.setState(state => ({
      categoryData: {
        ...state.categoryData,
        [name]: e.target.value
      }
    }));
  }

  submitForm = () => {
    if (this.state.mode === 'add') {
      this.saveCategory();
    } else {
      this.editCategory();
    }
  }

  saveCategory = () => {
    this.setState({
      loading: true
    });

    const categoryStoreRequestData = {
      ...this.state.categoryData
    };

    axios.post(APIs.categories.store, categoryStoreRequestData)
      .then(res => {
        this.props.onCategoryInsert!(res.data.data);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch(err => {
        toast.error(err.customErrorMessage ?? 'error in creating category');
        this.setState({
          loading: false
        });
      });
  }

  editCategory = () => {
    this.setState({
      loading: true
    });

    const categoryStoreRequestData = {
      ...this.state.categoryData
    };

    axios.put(APIs.categories.update + this.state.categoryData.id, categoryStoreRequestData)
      .then(() => {
        this.props.onCategoryUpdate!(this.state.categoryData);

        this.setState({
          loading: false,
          isModalShowing: false,
        });
      })
      .catch(err => {
        toast.error(err.customErrorMessage ?? 'error in updating category.');
        this.setState({
          loading: false
        });
      });
  }

  render() {
    return (
      <IonModal
        isOpen={this.state.isModalShowing}
        onDidDismiss={this.clearModal}
        swipeToClose={false}
        keyboardClose={false}
        backdropDismiss={false}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>New Category:</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={this.hideModal}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <form>
            <IonItem className="category-form-input">
              <IonLabel position="floating">Title:</IonLabel>
              <IonInput
                value={this.state.categoryData.title}
                onIonChange={(e) => this.setInput(e, 'title')}
              />
            </IonItem>
            <IonItem className="category-form-input">
              <IonLabel position="floating">Description:</IonLabel>
              <IonTextarea
                className="category-description"
                value={this.state.categoryData.description}
                onIonChange={(e) => this.setInput(e, 'description')}
              ></IonTextarea>
            </IonItem>
            <IonItem className="category-form-input">
              <IonLabel position="floating">Order:</IonLabel>
              <IonInput
                value={this.state.categoryData.order}
                onIonChange={(e) => this.setInput(e, 'order')}
                type="number"
              />
            </IonItem>
            <IonItem className="category-form-input">
              <IonLabel position="floating">Column Number:</IonLabel>
              <IonInput
                value={this.state.categoryData.column_number}
                onIonChange={(e) => this.setInput(e, 'column_number')}
                type="number"
              />
            </IonItem>

            <IonButton
              className="category-form-save-btn"
              color="primary"
              expand="full"
              onClick={this.submitForm}
            >Save</IonButton>
          </form>

          {/* Loading */}
          <IonLoading
            isOpen={this.state.loading}
            message={'Please wait...'}
          />
        </IonContent>
      </IonModal>
    )
  }
}

