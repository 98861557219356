export interface IProductSize {
  id: string;
  code: string;
  type: ProductSizeType;
  stock?: string | number;
  enable?: boolean | number;
  price?: string | number;
  price_with_vat?: string | number;
}

export type ProductSizeType = "small" | "normal" | "medium" | "large" | string;

export const PRODUCT_SIZES = {
  small: "small",
  normal: "normal",
  medium: "medium",
  large: "large",
};
