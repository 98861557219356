import React from "react";
import { IonItem, IonLabel, IonSkeletonText } from "@ionic/react";
import "./WarehouseItem.scss";
import { Avatar } from "../../Avatar/Avatar";
import classNames from "classnames";

interface WarehouseItemProps {
  aisle: string;
  bay: string;
  level: string;
  position: string;
  active?: boolean;
  isLoader?: boolean;
}

export const WarehouseItem: React.FC<WarehouseItemProps> = ({
  aisle,
  bay,
  level,
  position,
  active = true,
  isLoader,
}) => {
  if (isLoader) {
    return (
      <IonItem className="order-item">
        <IonSkeletonText
          animated
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
        <IonLabel className="order-item-info">
          <IonSkeletonText
            animated
            style={{ width: "40%", height: "16px", marginBottom: "5px" }}
          />
          <IonSkeletonText animated style={{ width: "100%", height: "12px" }} />
        </IonLabel>
      </IonItem>
    );
  }

  return (
    <IonItem className="warehouse-item">
      <Avatar
        label={active ? "A" : "D"}
        className={classNames({
          avatar: !active,
          "avatar active": active,
        })}
      />
      <span className="item">
        <p className="aisle">Aisle:</p>
        <h6>{aisle}</h6>
      </span>
      <span className="item">
        <p className="bay">Bay:</p>
        <h6>{bay}</h6>
      </span>
      <span className="item">
        <p className="level">Level:</p>
        <h6>{level}</h6>
      </span>
      <span className="item">
        <p className="position">Position:</p>
        <h6>{position}</h6>
      </span>
    </IonItem>
  );
};
