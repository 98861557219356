// @ts-nocheck
import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import { APIs } from "../../../../services/apiService";

import "./Load.scss";
import { toast } from "react-toastify";

export const LoadModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(props.displayModal);
  const [loadData, setLoadData] = useState({
    code: "",
    type: "full",
  });

  const toggleModal = () => {
    props.toggleModal();
  };

  const onSave = () => {
    let data = JSON.stringify({
      location_id: props.selected.id,
      type: loadData.type,
    });

    const loadRequestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios.get(APIs["product-size"].searchByCode(loadData.code)).then((res) => {
      if (res.data.data?.length > 0)
        axios
          .post(
            APIs["product-size"].load(res.data.data[0].id),
            data,
            loadRequestOptions
          )
          .then((res) => {
            if (res.data.status) {
              props.onLoadedData();
              toggleModal();
            }
          })
          .catch((e) => {
            toast.success("Something went wrong!");
          });
    });
  };

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
      showBackdrop={true}
      cssClass={"load-modal"}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Update Products Prices:</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={toggleModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form>
          <IonItem className="location-form-input">
            <IonLabel position="floating">Code:</IonLabel>
            <IonInput
              value={loadData.code}
              onIonChange={(e) =>
                setLoadData((data) => ({ ...data, code: e.target.value }))
              }
            />
          </IonItem>
          <IonItem className="store-form-input">
            <IonLabel>Half Pallet: </IonLabel>
            <IonToggle
              checked={loadData.type === "half" ? true : false}
              onIonChange={(e) =>
                setLoadData((data) => ({
                  ...data,
                  type: e.detail.checked ? "half" : "full",
                }))
              }
            />
          </IonItem>

          <IonButton
            className="location-form-save-btn"
            color="primary"
            expand="full"
            onClick={onSave}
          >
            Save
          </IonButton>
        </form>

        {/* Loading */}
        <IonLoading isOpen={loading} message={"Please wait..."} />
      </IonContent>
    </IonModal>
  );
};
