import { IonIcon, IonItem, IonLabel, IonSkeletonText } from "@ionic/react";
import classnames from "classnames";
import { mailOutline } from "ionicons/icons";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  IOrder,
  IOrderHalf,
  IOrderLoose,
  IOrderSize,
  ORDER_STATUS,
} from "../../../models/Order";
import { getDraftOrderById } from "../../../services/db";
import { Avatar } from "../../Avatar/Avatar";
import "./OrderItem.scss";
import { getDraftSizes } from "../../../utils";
import { attachOutline } from "ionicons/icons";

interface OrderItemProps {
  id?: string | number;
  routerLink?: string;
  store_name?: string;
  description?: string;
  avatar?: string;
  created_at?: string;
  order_status?: number;
  isLoader?: boolean;
  isDraft?: boolean;
  order?: IOrder;
  sizes?: Array<IOrderSize>;
  halves_data?: Array<IOrderHalf>;
  looses?: Array<IOrderLoose>;
  returns?: number;
  message?: string;
  received_payment?: string | number;
  created_by?: number;
  name?: string;
  half_count?: number;
  loose_count?: number;
  small_count?: number;
  normal_count?: number;
  medium_count?: number;
  large_count?: number;
  returns_count?: number;
  has_attachment?: 1 | 0;
  username?: string;
}

export const OrderItem: React.FC<OrderItemProps> = ({ ...orderDetail }) => {
  const [order, setOrder] = useState(orderDetail);
  const [counts, setCounts] = useState([{ type: "", count: 0 }]);

  useEffect(() => {
    if (order.isDraft && order.id) getDraftOrder(order.id);
    else if (order) {
      setCounts([
        {
          type: "Total",
          count:
            order.small_count! +
            order.normal_count! +
            order.medium_count! +
            order.large_count! +
            order.half_count!,
        },
        { type: "Small", count: order.small_count ?? 0 },
        { type: "Normal", count: order.normal_count ?? 0 },
        { type: "Medium", count: order.medium_count ?? 0 },
        { type: "Large", count: order.large_count ?? 0 },
        { type: "Half", count: order.half_count ?? 0 },
        { type: "Loose", count: order.loose_count ?? 0 },
      ]);
    }
  }, [order]);

  const getDraftOrder = async (orderId: string | number) => {
    try {
      let order = await getDraftOrderById(orderId);
      //@ts-ignore
      order = getDraftSizes({ ...order, orderId });
      setOrder(order);
    } catch (e) {
      toast.error("error recieving order data.");
    }
  };

  if (order.isLoader) {
    return (
      <IonItem className="order-item">
        <IonSkeletonText
          animated
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
        <IonLabel className="order-item-info">
          <IonSkeletonText
            animated
            style={{ width: "40%", height: "16px", marginBottom: "5px" }}
          />
          <IonSkeletonText animated style={{ width: "100%", height: "12px" }} />
        </IonLabel>
      </IonItem>
    );
  }

  return (
    <IonItem routerLink={order.routerLink} className="order-item">
      <Avatar
        className={classnames({
          draft: order.isDraft,
          "pending-order-status": order.order_status == ORDER_STATUS.pending,
          "complete-order-status": order.order_status == ORDER_STATUS.complete,
        })}
        label={
          order.avatar ?? order.isDraft
            ? "D"
            : order.order_status == ORDER_STATUS.complete
            ? "C"
            : "P"
        }
      />
      <IonLabel className="order-item-info">
        {!order.isDraft ? (
          <div className="detail">
            <h3>{`${order.store_name ?? ""} ${order.description ?? ""}`} </h3>
            <div className="icons">
              {order.returns_count! > 0 ? (
                <span className="icon" style={{ color: "#980026" }}>
                  RT
                </span>
              ) : null}
              {order.received_payment! > 0 ? (
                <span className="icon" style={{ color: "green" }}>
                  £
                </span>
              ) : null}
              {order.message ? (
                <IonIcon
                  icon={mailOutline}
                  className="icon"
                  style={{ color: "blue" }}
                />
              ) : null}
              {order.has_attachment === 1 ? (
                <IonIcon
                  icon={attachOutline}
                  className="icon"
                  style={{ color: "blue", transform: "rotate(45deg)" }}
                />
              ) : null}
            </div>
            <p className="date">
              {order.created_at
                ? `(${order.name} - ${moment
                    .utc(order.created_at)
                    .local()
                    .format("DD/MM/YYYY HH:mm")})`
                : ""}
            </p>
          </div>
        ) : (
          <div className="detail">
            <h3>{order.store_name}</h3>
            <p className="date">
              {`(${orderDetail?.username ?? ""} - ${moment
                // @ts-ignore
                .utc(order.start_at)
                .local()
                .format("DD/MM/YYYY HH:mm")})`}
            </p>
          </div>
        )}

        <div className="sizes">
          {/* @ts-ignore */}
          {counts?.map(({ type, count }, i) => {
            return (
              <span key={`size${i}`}>
                <p className="title">
                  {["Loose", "Total"].includes(type)
                    ? `${type}: `
                    : `${type?.substring(0, 1)?.toUpperCase()}:`}
                </p>
                {
                  <p className="count">
                    {type == "Total" && order?.loose_count! > 0
                      ? `${count} + ${order?.loose_count}L`
                      : `${count}`}
                  </p>
                }
              </span>
            );
          })}
        </div>
      </IonLabel>
    </IonItem>
  );
};
