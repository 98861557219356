import { initializeApp } from "firebase/app";
import "firebase/database";
import { getFirestore } from "firebase/firestore";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDVNZf2H7yVhKDwiwEdTHCPYBU9JFRUwhg",
  authDomain: "react-learning-3c852.firebaseapp.com",
  databaseURL: "https://react-learning-3c852.firebaseio.com",
  projectId: "react-learning-3c852",
  storageBucket: "react-learning-3c852.appspot.com",
  messagingSenderId: "474494568570",
  appId: "1:474494568570:web:5749475bf315cfcfad53c3",
  vapidKey:
    "BCWEXtgF0IVsDtXdXGwi1DJAdezFU1mr18WIed5fauFsInTF1issnCrQMGVTFf2Dk4s6XtfbLLN7Ban4CN_aVx4",
  serverKey:
    "AAAAbnoUiHo:APA91bGxOnKdwkGkFn0XF_wQf8L3eyfQ3IW2s3cbW-DTVJU2IANJzd1vHT4ZKuSI3VS_Xurm5lWbKFM_j3TBrjqB4ACO_5nYIUiZ8aOZiYJWLfWIn6UEBEjllHWHCuncPOvSYZesuCd3",
};

class Firebase {
  constructor() {
    this.app = initializeApp(FIREBASE_CONFIG);
    this.firestore = getFirestore();
  }
}

export default Firebase;
