import React from 'react';
import {Avatar} from '../../Avatar/Avatar';
import {IonItem, IonLabel, IonSkeletonText} from '@ionic/react';

import './ProductItem.scss';

interface ProductItemProps {
  name?: string;
  category?: string;
  isLoader?: boolean;
}


export const ProductItem: React.FC<ProductItemProps> = ({name, category, isLoader}) => {

  if (isLoader) {
    return (
      <IonItem className="order-item">
        <IonSkeletonText animated style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
        <IonLabel className="order-item-info">
          <IonSkeletonText animated style={{width: '40%', height: '16px', marginBottom: '5px'}}/>
          <IonSkeletonText animated style={{width: '100%', height: '12px'}}/>
        </IonLabel>
      </IonItem>
    )
  }

  return (
    <IonItem className="order-item">
      <Avatar label="P"/>
      <IonLabel className="order-item-info">
        <h3>{name}</h3>
        <p>{category}</p>
      </IonLabel>
    </IonItem>
  );
}
