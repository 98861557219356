import React from 'react';
import {Avatar} from '../../Avatar/Avatar';
import {IonItem, IonLabel, IonSkeletonText} from '@ionic/react';

import './UserItem.scss';

interface UserItemProps {
  name?: string;
  type?: string;
  isLoader?: boolean;
  avatar?: string;
}


export const UserItem: React.FC<UserItemProps> = ({name, type, isLoader, avatar}) => {

  if (isLoader) {
    return (
      <IonItem className="order-item">
        <IonSkeletonText animated style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
        <IonLabel className="order-item-info">
          <IonSkeletonText animated style={{width: '40%', height: '16px', marginBottom: '5px'}}/>
          <IonSkeletonText animated style={{width: '100%', height: '12px'}}/>
        </IonLabel>
      </IonItem>
    )
  }

  return (
    <IonItem className="order-item">
      <Avatar label="U" img={avatar}/>
      <IonLabel className="order-item-info">
        <h3>{name}</h3>
        <p>{type}</p>
      </IonLabel>
    </IonItem>
  );
}
