import React, { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import {
  IonPage,
  IonContent,
  IonLabel,
  IonItem,
  IonInput,
  IonIcon,
  IonButton,
  IonSpinner,
  IonAlert,
  useIonViewWillEnter,
} from "@ionic/react";
import { personOutline, lockClosedOutline } from "ionicons/icons";
import axios from "axios";
import "./Login.scss";

import { APIs } from "../../services/apiService";
import { setAuthToken } from "../../services/authService";
import { Actions } from "../../store/actionTypes";

import LogoImg from "../../assets/img/web-logo.png";

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useIonViewWillEnter(() => {
    if (localStorage.token) {
      history.replace("/dashboard");
    }
  }, []);

  /**
   * send login request
   */
  const login = (e: any): void => {
    e.preventDefault();

    setLoading(true);

    const requestData = {
      username,
      password,
    };

    axios
      .post(APIs.login, requestData)
      .then((res) => {
        if (res.data.status) {
          setAuthToken(res.data.data.token);

          axios
            .get(APIs.profile.get)
            .then((res) => {
              dispatch({
                type: Actions.AUTH.USER_LOGIN,
                payload: res.data.data,
              });
              history.replace("/dashboard");
            })
            .catch(() => {
              setAuthToken(null);
              setErrorAlert(true);
              setLoading(false);
            });
        }
      })
      .catch(() => {
        setErrorAlert(true);
        setLoading(false);
      });
  };

  return (
    <IonPage className="login-page">
      <IonContent className="login-content">
        <div className="login-header ion-padding">
          <img src={LogoImg} className="logo-img" alt="roynut" />
        </div>
        <h1 className="login-title">Login</h1>
        <IonLabel className="login-subtitle">
          please enter your username and password to login:
        </IonLabel>
        <form className="login-form" onSubmit={login}>
          <IonItem className="login-input">
            <IonIcon icon={personOutline} />
            <IonInput
              value={username}
              onIonChange={(e) => setUsername(e.detail.value!)}
              placeholder="username"
            />
          </IonItem>
          <IonItem className="login-input">
            <IonIcon icon={lockClosedOutline} />
            <IonInput
              value={password}
              onIonChange={(e) => setPassword(e.detail.value!)}
              placeholder="password"
              type="password"
            />
          </IonItem>
          <IonButton
            className="login-btn"
            color="danger"
            type="submit"
            disabled={loading}
          >
            {loading ? <IonSpinner name="lines-small" /> : "Login"}
          </IonButton>
        </form>

        {/*error alert*/}
        <IonAlert
          isOpen={errorAlert}
          onDidDismiss={() => setErrorAlert(false)}
          header={"Error"}
          message={"error in login."}
          buttons={["try again"]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
