import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ProductSizeItem } from "../ProductSizeItem/ProductSizeItem";
import { ProductSizeType } from "../../../models/ProductSize";
import { IOrder } from "../../../models/Order";

interface IProductRowProps {
  product: {
    id: number | string;
    title: string;
    order: number;
    sizes: Array<{
      id: number | string;
      type: ProductSizeType;
      stock: number;
      price: number;
    }>;
    vat?: number;
  };
  order: IOrder;
  showProductDetailsModal?: Function;
  changeOrderSize?: Function;
  addOrRemoveHalf?: Function;
  type: "new" | "last";
}

export const ProductRow: React.FC<IProductRowProps> = ({
  product,
  order,
  showProductDetailsModal,
  changeOrderSize,
  addOrRemoveHalf,
  type,
}) => {
  const [hasHalf, setHasHalf] = useState(false);

  const onNameClickHandler = () => {
    if (showProductDetailsModal) showProductDetailsModal(product.id);
  };

  const onHalfClickHandler = () => {
    if (addOrRemoveHalf)
      addOrRemoveHalf(
        product.sizes[1],
        product.sizes[1]?.stock > 0,
        product.vat
      );
  };

  useEffect(() => {
    setHasHalf(_.includes(order.halves, product.sizes[1]?.id));
  }, [order.halves]);

  return (
    <tr key={product.id} className="order-form-product">
      <td width="60%" className="order-form-product-name">
        <div className="d-flex justify-content-between">
          <div className="name" onClick={onNameClickHandler}>
            {product.title}
          </div>
          <div className="size-half" onClick={onHalfClickHandler}>
            {hasHalf ? "x6" : " "}
          </div>
        </div>
      </td>
      {_.map(product.sizes, (size) => (
        <ProductSizeItem
          key={size.id}
          size={size}
          order={order}
          changeOrderSize={changeOrderSize}
          formType={type}
          disabled={type === "last"}
          vat={product.vat}
        />
      ))}
    </tr>
  );
};
