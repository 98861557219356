import React from "react";
import _ from "lodash";
import { IOrder } from "../../../models/Order";

interface IProductRowProps {
  product: {
    id: number | string;
    title: string;
    order: number;
    sizes: Array<{
      id: number | string;
      type: any;
      stock: number;
      price: number;
    }>;
    vat?: number;
  };
  order: IOrder;
  showProductDetailsModal?: Function;
  changeOrderSize?: Function;
  addOrRemoveHalf?: Function;
  type: "normal" | "other";
  dataType: "stock" | "price";
}

export const ProductRow: React.FC<IProductRowProps> = ({
  product,
  type,
  dataType,
}) => {
  return (
    <tr key={product.id} className="order-form-product">
      <td width="60%" className="order-form-product-name">
        <div className="d-flex justify-content-between">
          <div className="name">{product.title}</div>
        </div>
      </td>
      {_.map(
        _.filter(product.sizes, (size) =>
          type === "normal" ? size.type === "normal" : size.type !== "normal"
        ),
        (size) => (
          <td style={{ textAlign: "center" }}>
            <div className="size-code">{size[dataType]}</div>
          </td>
        )
      )}
    </tr>
  );
};
