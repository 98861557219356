import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";

export const AttachmentModal = (props: any) => {
  const [isOpen] = useState(props.isOpen);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={props.toggleModal}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Attachment:</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={props.toggleModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="w-100 d-flex flex-row justify-content-center">
          <img
            src={props.src}
            alt="order-attachment"
            style={{ width: "auto", height: "auto", objectFit: "contain" }}
          />
        </div>
      </IonContent>
    </IonModal>
  );
};
