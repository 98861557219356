import React, { useEffect, useRef, useState } from "react";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonRefresher,
  IonRefresherContent,
  IonAlert,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSearchbar,
  IonButton,
} from "@ionic/react";
import { addOutline } from "ionicons/icons";

import axios from "axios";
import _ from "lodash";
import { UserItem } from "../../components/User/UserItem/UserItem";
import { APIs } from "../../services/apiService";
import { IPaginationMeta } from "../../models/PaginationMeta";
import { toast } from "react-toastify";
import { AddLocationModal } from "../../components/Warehouse/AddLocationmodal/AddLocationModal";
import { WarehouseItem } from "../../components/Warehouse/WarehouseItem/WarehouseItem";
import { LoadUnLoadModal } from "../../components/Warehouse/LoadUnLoadModal/LoadUnLoad";

export const Warehouse: React.FC = () => {
  // TODO : change loading to content-loader instead of loading
  const [loading, setLoading] = useState(false);
  const [showDeleteLocationAlert, setShowDeleteLocationAlert] = useState(false);
  const [selectedLocationToDelete, setSelectedLocationToDelete] =
    useState<any>(null);
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);
  const [displayLoadUnLoadModal, setDisplayLoadUnLoadModal] = useState(false);
  // TODO : implement search scenario
  const [searchLocation, setSearchLocation] = useState("");
  const [locations, setLocations] = useState<any[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    current_page: 1,
    per_page: 30,
    last_page: 1,
    total: 0,
  });

  const locationAddModalRef: any = useRef();
  const loadUnLoadModalRef: any = useRef();

  const getLocations = async (page: number, type = "search") => {
    return await axios
      .get(APIs.locations.search, {
        params: {
          per_page: meta.per_page,
          page,
          search_key: searchLocation.length > 2 ? searchLocation : undefined,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        toast.error(
          err.customErrorMessage ?? "error in getting locations list"
        );
        return [];
      });
  };

  const addNewLocationToList = (location: any) => {
    // TODO : fix this code
    getInitialLocations();
    toast.success("Location has been saved");
  };

  const updateLocationInList = (location: any) => {
    // TODO : fix this code
    getInitialLocations();
    toast.success("Location has been saved");
  };

  const confirmDeleteLocation = (location: any) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    setShowDeleteLocationAlert(true);
    setSelectedLocationToDelete(location);
  };

  const deleteLocation = () => {
    setLoading(true);

    axios
      .delete(APIs.locations.delete(selectedLocationToDelete!.id))
      .then((res) => {
        doRefresh();
        setSelectedLocationToDelete(null);
        setShowDeleteLocationAlert(false);
        setLoading(false);
        toast.success("location deleted successfully");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in deleting location");
      });
  };

  const editLocation = (location: any) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    locationAddModalRef.current.showEditModal(location);
  };

  const doFetch = async (event?: any) => {
    let data = await getLocations(meta.current_page + 1);

    setLocations(locations.concat(data.data!));
    setMeta(data.meta!);

    event.target.complete();

    if (locations.length >= meta.total) setIsInfinityLoadingDisabled(true);
  };

  const doRefresh = async (event?: any) => {
    setLoading(true);
    setLocations([]);
    setIsInfinityLoadingDisabled(false);

    let data = await getLocations(1, "show");
    setLocations(data.data);
    setMeta(data.meta!);
    setLoading(false);

    if (event) event.target.complete();
  };

  const showAddModal = () => {
    locationAddModalRef.current.showModal();
  };

  const getInitialLocations = async () => {
    setLoading(true);

    let data = await getLocations(1, "show");
    setLocations(data.data);
    setMeta(data.meta!);

    setLoading(false);
  };

  /**
   * life cycles and effects
   */
  useEffect(() => {
    getInitialLocations();
  }, []);

  useEffect(() => {
    if (searchLocation?.length > 0) {
      const searchLocations = async () => {
        setLoading(true);
        setMeta((prevMeta) => ({
          ...prevMeta,
          current_page: 1,
        }));

        let data = await getLocations(meta.current_page);

        setLocations(data.data);
        setMeta(data.meta!);
        setLoading(false);
      };

      searchLocations();
    }
  }, [searchLocation]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="danger">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dashboard" />
          </IonButtons>

          <IonButtons slot="end" className="menu-container">
            <IonButton onClick={() => setDisplayLoadUnLoadModal(true)}>
              Load | UnLoad
            </IonButton>
          </IonButtons>

          <IonTitle>Warehouse</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonList>
          {loading
            ? _.times(10, (i) => <UserItem key={i} isLoader={true} />)
            : locations?.map((location, index) => (
                <IonItemSliding key={index}>
                  <WarehouseItem
                    aisle={location.aisle}
                    bay={location.bay}
                    level={location.level}
                    position={location.position}
                    active={location.active}
                  />
                  <IonItemOptions side="start">
                    <IonItemOption
                      color="primary"
                      onClick={() => editLocation(location)}
                    >
                      Edit
                    </IonItemOption>
                  </IonItemOptions>
                  <IonItemOptions side="end">
                    <IonItemOption
                      color="danger"
                      onClick={() => confirmDeleteLocation(location)}
                    >
                      Delete
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              ))}
        </IonList>

        <IonInfiniteScroll
          threshold="10px"
          onIonInfinite={doFetch}
          disabled={isInifinityLoadingDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          />
        </IonInfiniteScroll>

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton color="danger" onClick={showAddModal}>
            <IonIcon icon={addOutline} />
          </IonFabButton>
        </IonFab>

        <AddLocationModal
          ref={locationAddModalRef}
          onLocationInsert={addNewLocationToList}
          onLocationUpdate={updateLocationInList}
        />

        {displayLoadUnLoadModal && (
          <LoadUnLoadModal
            displayModal={displayLoadUnLoadModal}
            toggleModal={() =>
              setDisplayLoadUnLoadModal(!displayLoadUnLoadModal)
            }
            locations={locations}
            setLocations={setLocations}
            getInitialLocations={getInitialLocations}
          />
        )}

        <IonAlert
          isOpen={showDeleteLocationAlert}
          onDidDismiss={() => setShowDeleteLocationAlert(false)}
          header={"Delete"}
          message={"Do you want to delete the location?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Delete",
              handler() {
                deleteLocation();
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};
